import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import compareVersions from 'compare-versions';
import packageJson from '../../../../../package.json';
import {
  HealthCheckRepository,
  Version,
} from '../repositories/health-check.repository';
import { ModalService } from '@bling-fe/shared/modals/base-modal';
import { UpdateInfoModalComponent } from '@flink-legacy/modals/update-info-modal/update-info-modal.component';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  endpointVersion: string;
  appVersion = packageJson.version;
  private message: BehaviorSubject<string> = new BehaviorSubject(
    this.translate.instant('APP_VERSION.UPDATE')
  );

  constructor(
    private translate: TranslateService,
    private healthCheckRepo: HealthCheckRepository,
    private modalService: ModalService
  ) {}

  async checkVersion() {
    this.healthCheckRepo.getVersion().subscribe((data: Version) => {
      this.endpointVersion = data.version;
      this.translate.stream('APP_VERSION.UPDATE').subscribe(translation => {
        this.message.next(translation);
      });
      if (this.isUpdateSuggested()) {
        this.showUpdateModal();
      }
    });
  }

  isUpdateSuggested() {
    if (!Capacitor.isNative) {
      return false;
    }
    if (!this.endpointVersion || !this.appVersion) {
      return false;
    }
    return compareVersions.compare(this.endpointVersion, this.appVersion, '>');
  }

  private async showUpdateModal() {
    await this.modalService.presentAndGetResult(UpdateInfoModalComponent, {
      message: this.message,
    });
  }
}
