<!--
    Mobile layout uses ion-menu

    NOTE: There is currently no need for slide menu.
          But that may change in the future. See `tabs-navigation` input
          `showMenuToggle` to display menu toggle button or use
          `MenuController` as usual.
-->
<ng-container *flMobileOnly>
  <ion-menu
    contentId="main"
    type="overlay"
    [swipeGesture]="false"
    side="start"
    class="mobile"
  >
    <ion-content class="flex--center">
      <ng-container *ngTemplateOutlet="mobile"></ng-container>
    </ion-content>
  </ion-menu>
</ng-container>

<!--
    Desktop layout uses ion-content with list of links
-->
<ng-container *flDesktopOnly>
  <ion-content class="desktop flex--center">
    <ng-container *ngTemplateOutlet="desktop"></ng-container>
  </ion-content>
</ng-container>

<ng-template #mobile>
  <ion-menu-toggle>
    <div class="compensate-tabs-navigation">
      <div *ngFor="let section of mobileMenuItems">
        <h3
          *ngIf="section.label"
          class="text--sm p-0 px-4 m-0"
        >
          {{ section.label | translate }}
        </h3>
        <ion-card class="m-4 mb-5">
          <ion-card-content class="py-2">
            <ion-list>
              <ion-item
                *ngFor="let item of section.items; let last = last"
                (click)="navigateUrl(item.url)"
                [lines]="last ? 'none' : 'full'"
                [class]="item.class"
                button
                [detail]="false"
              >
                <ion-label class="d-flex ion-align-items-center">
                  <svg-icon
                    [key]="item.icon"
                    size="sm"
                    class="menu-item--icon mr-3"
                  ></svg-icon>
                  {{ item.label | translate }}
                </ion-label>
                <svg-icon
                  *ngIf="!item.separator && item.showArrowIcon"
                  key="chevron-forward"
                  size="sm"
                  slot="end"
                ></svg-icon>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </ion-menu-toggle>
</ng-template>

<ng-template #desktop>
  <ion-list lines="none">
    <ng-container *ngIf="currentTenant$ | async as tenant">
      <ion-item class="app-logo">
        <div class="app-logo-wraper">
          <a [routerLink]="['/']">
            <ion-img
              *ngIf="tenant !== 'loading'"
              [src]="tenant.tenant_setting.app_logo"
              class="app-logo-img"
            ></ion-img>
          </a>
        </div>
      </ion-item>
    </ng-container>
    <ng-container *ngFor="let item of desktopMenuItems">
      <ion-item
        [routerLink]="[item.url]"
        routerLinkActive="router-link-active"
        [detail]="false"
        button
      >
        <svg-icon
          [key]="item.icon"
          size="lg"
          slot="start"
        ></svg-icon>
        <ion-label>
          {{ item.label | translate }}
        </ion-label>
        <bl-notifications-badge
          *ngIf="(item.notificationCount$ | async) > 0"
          [count]="item.notificationCount$ | async"
          class="ml-4"
        ></bl-notifications-badge>
      </ion-item>
    </ng-container>
  </ion-list>
</ng-template>
