import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutState } from './layout.reducers';

const getFeatureState = createFeatureSelector<LayoutState>('layoutState');

export const getScreensize = createSelector(
  getFeatureState,
  (state: LayoutState) => state.screensize
);

export const getBreakpoint = createSelector(
  getFeatureState,
  (state: LayoutState) => state.breakpoint
);

export const getPageTransitionAnimated = createSelector(
  getFeatureState,
  (state: LayoutState) => state.pageTransitionAnimated
);
