<fl-warning-card
  [title]="title | translate"
  [content]="content | translate"
>
  <br />
  <ion-button
    (click)="retry.emit()"
    class="mt-3"
    color="danger"
    size="small"
  >
    {{ retryText | translate }}
  </ion-button>
</fl-warning-card>
