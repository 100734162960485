import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { User } from '@flink-legacy/core/declarations/user.interface';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RedirectIfTermsAccepted implements CanActivateChild {
  constructor(private userStore: Store<User>, private router: Router) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userStore.select(getCurrentUser).pipe(
      filter(x => x !== null),
      map(user => user as User),
      map(user => {
        if (user.terms_accepted) {
          return this.router.parseUrl('/home');
        } else {
          return true;
        }
      })
    );
  }
}
