import ical from 'ical-generator';
import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Capacitor } from '@capacitor/core';

import { LoadingService } from './loading.service';
import { ArticleEvent } from '@flink-legacy/core/declarations/article.interface';
import { Store } from '@ngrx/store';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { filter, first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  frontendUrl: string;
  constructor(
    private calendar: Calendar,
    private loadingService: LoadingService,
    tenantStore: Store<TenantState>
  ) {
    tenantStore
      .select(getCurrentTenant)
      .pipe(
        filter(t => t !== 'loading'),
        map(t => t as Tenant),
        map(t => t.tenant_setting),
        map(t => t.app_frontend_url),
        first()
      )
      .subscribe(url => {
        this.frontendUrl = url;
      });
  }

  createDesktopEvent(event: ArticleEvent) {
    const cal = ical();
    cal.createEvent({
      start: event.start_at,
      end: event.end_at,
      summary: event.title,
      description: event.content_markdown,
      location: event.location,
      url: `${this.frontendUrl}/event/${event.id}`,
    });
    const calendarData = cal.toString();
    const blob = new Blob([calendarData]);

    const filename = event.title.split(' ').join('-').toLowerCase();
    saveAs(blob, filename + '.ics');
  }

  async createNativeEvent(event: ArticleEvent) {
    const starting = dayjs(event.start_at).toDate();
    const ending = dayjs(event.end_at).toDate();

    try {
      /**
       * Because iOS has bug that doesn't apparently resolve promise after cancelling
       * calendar modal, we can't dismiss loading modal (because promise is never resolved).
       * It works just fine if user actually add event into native calendar.
       *
       * So loading feedback is enabled only for android (sigh...)
       */
      if (Capacitor.getPlatform() === 'android') {
        await this.loadingService.show();
      }

      await this.calendar.createEventInteractivelyWithOptions(
        event.title,
        event.location,
        event.content_markdown,
        starting,
        ending,
        { url: `${this.frontendUrl}/event/${event.id}` }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      if (Capacitor.getPlatform() === 'android') {
        await this.loadingService.dismiss();
      }
    }
  }

  createCalendarEvent(event: ArticleEvent) {
    if (Capacitor.isNativePlatform()) {
      this.createNativeEvent(event);
    } else {
      this.createDesktopEvent(event);
    }
  }
}
