import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'bl-notifications-badge',
  standalone: true,
  imports: [CommonModule, IonicModule],
  template: `
    <ion-badge
      class="text--xxs flex--center"
      [ngClass]="{
        'badge--sm': size === 'sm',
        'badge--md': size === 'md'
      }"
      [color]="color"
    >
      {{ count > 99 ? '99+' : count }}
    </ion-badge>
  `,
  styleUrls: ['./ui-notifications-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNotificationsBadgeComponent {
  @Input() count!: number;
  @Input() size: 'sm' | 'md' = 'md';
  @Input() color: 'primary' | 'danger' = 'danger';
}
