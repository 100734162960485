<!--

App Layout
----------

Responsive main layout of application.

It supports layout for mobile and desktop screen sizes.

Desktop:

|--------------------------------------|
|          |                           |
| fl-      |                           |
| main-    |    ion-router-outlet      |
| menu     |    (responsive content)   |
| (fixed)  |                           |
|          |                           |
|----------|---------------------------|

Mobile:

|-----------|     |---------------------------|
|           |     |                           |
| fl-main-  |     |    ion-router-outlet      |
| menu      |     |    (responsive content)   |
|           |  +  |                           |
| (overlay) |     |                           |
|           |     |---------------------------|
|           |     |   fl-tabs-navigation      |
|-----------|     |---------------------------|

-->

<div class="app-layout">
  <ion-split-pane contentId="main">
    <fl-main-menu></fl-main-menu>

    <fl-tabs-navigation
      *flMobileOnly
      [hide]="withoutTabs"
      [attr.role]="'navigation'"
      [attr.aria-label]="'primary'"
    ></fl-tabs-navigation>

    <!-- the main content -->
    <ion-router-outlet
      id="main"
      [ngClass]="withoutTabs ? '' : 'compensate-footer'"
      [animated]="pageTransitionAnimated$ | async"
      [environmentInjector]="environmentInjector"
    ></ion-router-outlet>
  </ion-split-pane>
</div>
