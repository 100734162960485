import { inject } from '@angular/core';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { Store } from '@ngrx/store';
import { filter, map, first } from 'rxjs';

export const instantMessagingEnabledGuard = () => {
  const tenantStore = inject(Store<Tenant>);
  return tenantStore.select(getCurrentTenant).pipe(
    filter(t => t !== 'loading'),
    map(t => t as Tenant),
    map(t => t.tenant_setting),
    first(),
    map(tenantSetting => tenantSetting.instant_messaging_enabled)
  );
};
