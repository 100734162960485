import { ArticlePollOption } from './article-poll-option.interface';
import { BasicDocument } from './document.interface';
import { Base64String, FileUploadParams } from './file.interface';
import { Group } from './group.interface';
import { Picture } from './picture.interface';
import { Settlement } from './settlement.interface';
import { User } from './user.interface';

export interface ArticlesResponse {
  total_pages: number;
  articles: Article[];
}

// ArticleEntity in BE

export type Article =
  | ArticleArticle
  | ArticleEvent
  | ArticlePoll
  | ArticleMarketplace;

export interface ArticleBase {
  id: number;
  title: string;
  content_html: string;
  article_type: ArticleType;
  user: User;
  comment_count: number;
  like_count: number;
  article_cover_picture?: Picture;
  picture: string;
  picture_preview?: string;
  pictures: Picture[];
  important?: boolean;
  video_url?: string;
  pinned: boolean;
  /**
   * if pinned
   */
  pin_until?: Date;
  liked?: boolean;
  created_at: string;
  updated_at: string;
  content_markdown?: string;
  show_in_feed: boolean;
  /**
   * if article origin is 'group'
   */
  group?: Group;
  /**
   * if article origin is 'settlement'
   */
  settlement?: Settlement;
}

export interface ArticleArticle extends ArticleBase {
  article_type: ArticleType.ARTICLE;
  documents: ArticleDocument[];
}

export interface ArticleEvent extends ArticleBase {
  article_type: ArticleType.EVENT;
  location: string;
  start_at: string;
  end_at: string;
  documents: ArticleDocument[];
}

export interface ArticlePoll extends ArticleBase {
  article_type: ArticleType.POLL;
  poll_options: ArticlePollOption[];
  documents: ArticleDocument[];
}

export interface ArticleMarketplace extends ArticleBase {
  article_type: ArticleType.MARKETPLACE;
  price: number;
  phone: string;
  marketplace_type: MarketplaceType;
  email: string;
  documents: ArticleDocument[];
}

/**
 * Article documents are special and doesn't extend `DocumentEntity`
 * in the BE. It shares some properties tho.
 */
export type ArticleDocument = Pick<
  BasicDocument,
  'id' | 'url' | 'content_type' | 'title'
>;

export enum ArticleType {
  ARTICLE = 'article',
  EVENT = 'event',
  MARKETPLACE = 'marketplace',
  VOTE = 'vote',
  POLL = 'poll',
}

export enum MarketplaceType {
  SELL = 'sell',
  BUY = 'buy',
}

export enum ArticleOrigin {
  MY_SETTLEMENT = 'my_settlement',
  GROUPS = 'groups',
}

/*
 * ArticleParams – permitted params for update/create
 *
 */

export interface ArticleParams {
  article_type: ArticleType;
  content_markdown: string;
  title?: string;
  price?: number;
  marketplace_type?: MarketplaceType;
  group_id?: number;
  settlement_id?: number;
  poll_options?: ArticlePollOption[];
  video_url?: string;
  article_cover_picture?: Base64String;
  pictures?: Base64String[];
  documents?: ArticleDocumentParams[];
  deleted_pictures?: number[];
  deleted_documents?: number[];
  start_at?: string;
  end_at?: string;
  location?: string;
  important?: boolean;
  show_in_feed?: boolean;
}

export type ArticleDocumentParams =
  | Pick<ArticleDocument, 'id' | 'title'>
  | FileUploadParams;

/*
 * Params for filtering articles
 */

export interface ArticleFilterParams {
  origin?: 'all' | 'my_settlement';
  'group_ids[]'?: number[];
  'article_types[]'?: ArticleTypeFilterValue[];
  order_by?: string;
  include_posts_from_groups_user_is_not_member_of?: boolean;
  filter_by_show_in_feed?: boolean;
  for_current_user?: string;
  period_end?: string; // date in format 'YYYY-MM-DD HH:MM:SS'
  period_start?: string; // date in format 'YYYY-MM-DD HH:MM:SS'
  member_only?: string;
  past_events?: boolean;
  future_events?: boolean;
}

export interface ArticleEventFilter {
  groupIds?: number[];
  origin?: 'all' | 'my_settlement';
  period?: Period;
  memberOnly?: boolean;
  periodStart?: Date;
  periodEnd?: Date;
}

export type Period = 'all' | 'today' | 'week' | 'month';

export enum ArticleTypeFilterValue {
  ARTICLE = 'article',
  POLL = 'poll',
  EVENT = 'event',
  MARKETPLACE = 'marketplace',
}

export const INITIAL_EVENT_FILTERS: ArticleEventFilter = {
  period: 'all',
  memberOnly: false,
  origin: 'all',
};

export interface ArticleDeleteParams {
  reason_for_deletion: string;
}
