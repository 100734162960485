import {
  User,
  UserFilterParams,
} from '@flink-legacy/core/declarations/user.interface';
import { createAction, props } from '@ngrx/store';

export const SET_USERS_FILTER = 'USER:SET_USERS_FILTER';
export const setCurrentUser = createAction(
  'USER:SET_CURRENT_USER',
  props<User>()
);
export const logout = createAction('USER:LOGOUT');
export const setUsersFilter = createAction(
  SET_USERS_FILTER,
  props<UserFilterParams>()
);
