import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { marker as _ } from '@bling-fe/shared/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { WarningCardComponent } from '../warning-card/warning-card.component';

@Component({
  selector: 'fl-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule, WarningCardComponent],
})
export class ErrorBoxComponent {
  @Input() title = _('COMMON.ERROR_BOX.TITLE');
  @Input() content = _('COMMON.ERROR_BOX.CONTENT');
  @Input() retryText = _('COMMON.ERROR_BOX.RETRY_TEXT');
  @Output() retry = new EventEmitter<void>();

  constructor() {}
}
