import { User } from '@flink-legacy/core/declarations/user.interface';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TermsAcceptedGuard implements CanActivateChild {
  constructor(private userStore: Store<User>, private router: Router) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userStore.select(getCurrentUser).pipe(
      filter(x => x !== null),
      map(user => user as User),
      map(user => {
        if (user.terms_accepted) {
          return true;
        } else {
          return this.router.parseUrl('/terms-and-conditions');
        }
      })
    );
  }
}

export const termsAcceptedGuard = () => {
  const userStore = inject(Store);
  const router = inject(Router);

  return userStore.select(getCurrentUser).pipe(
    filter(x => x !== null),
    map(user => user as User),
    map(user => {
      if (user.terms_accepted) {
        return true;
      } else {
        return router.parseUrl('/terms-and-conditions');
      }
    })
  );
};
