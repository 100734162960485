import { SetEditingComment, SET_EDITING_COMMENT } from './comment.actions';
import { CommentState, initialState } from './comment.state';

export const commentReducers = (
  state: CommentState = initialState,
  action: Actions
): CommentState => {
  let newState: CommentState;

  switch (action.type) {
    case SET_EDITING_COMMENT:
      newState = {
        ...state,
        editingComment: action.payload,
      };
      break;
    default:
      newState = state;
  }

  return newState;
};

export type Actions = SetEditingComment;
