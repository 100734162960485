import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthenticationService } from '@flink-legacy/shared/services/authentication.service';
import { specialHosts } from '@flink-legacy/core/declarations/constants';

@Injectable({
  providedIn: 'root',
})
export class CanSelectTenantGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.isLoggedIn().pipe(
      map(logged => {
        if (logged) {
          return this.router.parseUrl('/');
        } else {
          return specialHosts.includes(window.location.hostname)
            ? this.router.createUrlTree(['/login'])
            : true;
        }
      })
    );
  }
}
