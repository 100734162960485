<ion-item
  lines="none"
  [class]="withPadding ? 'with-padding' : 'ion-no-padding'"
  detail="false"
  (click)="openDocument()"
>
  <div slot="start">
    <fl-document-image [document]="item"></fl-document-image>
  </div>
  <span class="ml-5 font--base">
    {{ item.title }}
  </span>
  <div
    slot="end"
    class="header__toolarea"
    *ngIf="showOptions"
  >
    <ion-button
      class="more-actions-btn"
      fill="clear"
      (click)="handleOptions($event)"
      [attr.aria-label]="'COMMON.LABELS.OPTIONS' | translate"
      [title]="'COMMON.LABELS.OPTIONS' | translate"
    >
      <svg-icon
        slot="icon-only"
        key="dots-three"
        class="color--darkblue font--base"
        aria-hidden="true"
      ></svg-icon>
    </ion-button>
  </div>
</ion-item>
