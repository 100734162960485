<div class="inner-content ion-text-center">
  <h5 aria-level="1">{{ props.title | translate }}</h5>
  <ion-text class="text--sm">
    {{ props.description | translate }}
  </ion-text>

  <ion-item
    class="mt-6"
    lines="none"
  >
    <ion-label
      position="stacked"
      class="text--xxs text--semibold mb-2 required"
    >
      {{ "MODALS.CONFIRM_WITH_REASON.REASON.LABEL" | translate }}
    </ion-label>
    <ion-input
      type="textarea"
      [formControl]="confirmReason"
      [placeholder]="
        'MODALS.CONFIRM_WITH_REASON.REASON.PLACEHOLDER' | translate
      "
      required
    ></ion-input>
  </ion-item>

  <ion-button
    class="mt-4"
    (click)="dismiss({ finishAction: true, modalMessage: confirmReason.value })"
    expand="block"
    [color]="props.confirmColor || 'primary'"
    [disabled]="!confirmReason.value"
    data-cy="confirm-delete-btn"
  >
    {{ props.confirmLabel | translate }}
  </ion-button>

  <ion-button
    class="mt-4"
    expand="block"
    color="light"
    (click)="dismiss({ finishAction: false })"
  >
    {{ props.cancelLabel | translate }}
  </ion-button>
</div>
