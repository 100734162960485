import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BaseSubscriberAbstract } from '@flink-legacy/core/base-subscriber.abstract';
import { IonicModule } from '@ionic/angular';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({})
export class SelectModalBaseComponentIconsModule {}

@Component({
  selector: 'fl-select-modal-base',
  templateUrl: './select-modal-base.component.html',
  styleUrls: ['./select-modal-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IonicModule,
    SvgIconComponent,
    TranslateModule,
    NgIf,
    SelectModalBaseComponentIconsModule,
  ],
  standalone: true,
})
export class SelectModalBaseComponent extends BaseSubscriberAbstract {
  @Input() title: string;
  @Input() searchPlaceholder: string = this.translate.instant(
    'COMMON.LABELS.SEARCH'
  );
  @Input() showClose = true;
  @Input() showHeaderBorder = false;
  @Input() showSearchBar = true;
  @Output() search = new EventEmitter<string>();
  @Output() closeClick = new EventEmitter<void>();

  constructor(private translate: TranslateService) {
    super();
  }
}
