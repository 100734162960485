import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsfeedState } from './newsfeed.state';

const getFeatureState = createFeatureSelector<NewsfeedState>('newsfeedState');

export const getCurrentNewsfeedFilters = createSelector(
  getFeatureState,
  (state: NewsfeedState) => state.currentNewsfeedFilters
);

export const isNewsfeedFilterActive = createSelector(
  getFeatureState,
  (state: NewsfeedState) => {
    const filters = state.currentNewsfeedFilters;

    return (
      filters.filterByGroup.length !== 0 ||
      filters.filterByType.length !== 4 ||
      filters.origin === 'my_settlement'
    );
  }
);
