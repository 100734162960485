<fl-select-modal-base
  [title]="'MODALS.SELECT_USER.TITLE' | translate"
  [showClose]="true"
  (closeClick)="
    dismiss({
      label: props.value.full_name,
      image: props.value.avatar_url,
      value: props.value
    })
  "
  (search)="searchFilter.next($event)"
  class="ion-page"
>
  <fl-infinite-pagination
    *paginate="users$; let users; let data = data"
    [data]="data"
    (fetchNext)="fetchNextPage()"
    (refreshPage)="doRefresh($event)"
  >
    <ion-list
      lines="none"
      class="mt-5"
    >
      <ion-item
        detail
        *ngFor="let user of users"
        (click)="
          dismiss({
            label: user.full_name,
            image: user.avatar_url,
            value: user
          })
        "
      >
        <fl-avatar
          [user]="user"
          [clickable]="false"
          [size]="40"
          class="mr-4"
          slot="start"
        ></fl-avatar>
        <ion-label class="font--base">{{ user.full_name }}</ion-label>
      </ion-item>
    </ion-list>

    <fl-empty-box
      ifEmpty
      class="my-6"
      [content]="'MODALS.SELECT_USER.NO_USERS' | translate"
    ></fl-empty-box>

    <fl-skeleton
      ifLoading
      type="fl-invite-user-item"
      class="d-block mt-5"
      [count]="6"
    ></fl-skeleton>

    <fl-error-box
      ifError
      class="my-6"
      (retry)="fetchNextPage()"
    ></fl-error-box>
  </fl-infinite-pagination>
</fl-select-modal-base>
