import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PredefinedColors } from '@ionic/core';
import { DEFAULT_TOAST_DURATION } from '@flink-legacy/core/declarations/constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private toastController: ToastController,
    private injector: Injector
  ) {}

  default(notificationText: string, params?: unknown): void {
    this.showNotification('dark', notificationText, params);
  }

  success(notificationText: string, params?: unknown): void {
    this.showNotification('success', notificationText, params);
  }

  error(
    notificationText: string,
    params?: unknown,
    options?: { isTranslated?: boolean }
  ): void {
    this.showNotification('danger', notificationText, params, options);
  }

  private showNotification(
    color: PredefinedColors,
    notificationText: string,
    params?: unknown,
    options?: { isTranslated?: boolean }
  ): void {
    this.toastController
      .create({
        message: options?.isTranslated
          ? notificationText
          : this.translateNotificationText(
              notificationText,
              this.translateParams(params)
            ),
        color,
        duration: DEFAULT_TOAST_DURATION,
        htmlAttributes: { 'data-cy': 'notification-toast' },
      })
      .then(toast => toast.present());
  }

  private translateNotificationText(
    notificationText: string | string[],
    params: unknown
  ) {
    // to prevent circular dependency
    const i18n = this.injector.get(TranslateService);
    if (typeof notificationText === 'string') {
      return i18n.instant(notificationText, this.translateParams(params));
    } else {
      const translations = i18n.instant(
        notificationText,
        this.translateParams(params)
      );
      return notificationText.reduce((previous, current, index) => {
        previous += (index > 0 ? '\n' : '') + translations[current];
        return previous;
      }, '');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private translateParams(params: any): any {
    const i18n = this.injector.get(TranslateService);
    for (const property in params) {
      if (
        params.hasOwnProperty(property) &&
        typeof params[property] === 'string'
      ) {
        params[property] = i18n.instant(params[property]);
      }
    }
    return params;
  }
}
