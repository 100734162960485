import { map, switchMap } from 'rxjs/operators';
import { TenantService } from './../../shared/services/tenant.service';
import { Component, OnInit, inject } from '@angular/core';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectOption } from '@flink-legacy/shared/components/forms/select/select.component';
import { ModalAbstractComponent } from '@bling-fe/shared/modals/base-modal';
import { SelectModalBaseComponent } from '../select-modal-base/select-modal-base.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { SkeletonComponent } from '@flink-legacy/shared/components/skeleton/skeleton.component';
import { ErrorBoxComponent } from '@flink-legacy/shared/components/error-box/error-box.component';
import { LogoComponent } from '@flink-legacy/shared/components/logo/logo.component';

@Component({
  selector: 'fl-select-tenant-modal',
  templateUrl: './select-tenant-modal.component.html',
  styleUrls: ['./select-tenant-modal.component.scss'],
  standalone: true,
  imports: [
    SelectModalBaseComponent,
    IonicModule,
    TranslateModule,
    NgFor,
    NgIf,
    AsyncPipe,
    SkeletonComponent,
    ErrorBoxComponent,
    LogoComponent,
  ],
})
export class SelectTenantModalComponent
  extends ModalAbstractComponent<{ value: Tenant }, SelectOption<Tenant>>
  implements OnInit
{
  tenants$: Observable<Fetchable<Tenant[]>>;
  public searchFilter = new BehaviorSubject<string>('');
  private tenantService = inject(TenantService);

  ngOnInit(): void {
    this.tenants$ = this.tenantService.data$.pipe(
      switchMap(tenants =>
        this.searchFilter.pipe(
          map(search => ({
            ...tenants,
            data: tenants.data.filter(t =>
              t.name.toLowerCase().includes(search.toLowerCase())
            ),
          }))
        )
      )
    );
    this.fetchTenants();
  }

  fetchTenants() {
    this.tenantService.fetch();
  }
}
