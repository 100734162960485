<fl-select-modal-base
  [title]="'MODALS.SELECT_TENANT.TITLE' | translate"
  [showClose]="false"
  (closeClick)="dismiss()"
  (search)="searchFilter.next($event)"
  class="ion-page"
  data-cy="select-tenant-modal"
>
  <h2 class="color--dark text--sm text--medium pt-5 pb-2">
    {{ "MODALS.SELECT_TENANT.SUBTITLE" | translate }}
  </h2>
  <ng-container *ngIf="tenants$ | async as tenants">
    <ion-list
      lines="none"
      tabindex="0"
    >
      <ion-item
        *ngFor="let tenant of tenants.data"
        (click)="dismiss({ label: tenant.name, value: tenant })"
      >
        <ion-thumbnail slot="start">
          <fl-logo [tenant]="tenant"></fl-logo>
        </ion-thumbnail>
        <ion-label class="font--base">{{ tenant.name }}</ion-label>
      </ion-item>
    </ion-list>
    <fl-skeleton
      *ngIf="tenants.state === 'loading'"
      type="group-member-item"
      [count]="3"
    ></fl-skeleton>

    <fl-error-box
      *ngIf="tenants.state === 'error'"
      class="mx-4 my-6"
      (retry)="fetchTenants()"
    ></fl-error-box>
  </ng-container>
</fl-select-modal-base>
