<fl-select-modal-base
  [title]="'MODALS.SELECT_DOCUMENT_CATEGORY.TITLE' | translate"
  (closeClick)="dismiss()"
  [showSearchBar]="false"
  [showHeaderBorder]="true"
  class="ion-page"
>
  <h2 class="color--medium text--sm text--medium pb-2">
    {{ "MODALS.SELECT_DOCUMENT_CATEGORY.SUBTITLE" | translate }}
  </h2>

  <ion-list
    lines="none"
    *ngIf="documentCategories$ | async as documentCategories"
  >
    <ion-item
      detail
      (click)="dismiss()"
    >
      <ion-label class="font--base">
        {{ "COMMON.LABELS.NONE" | translate }}
      </ion-label>
    </ion-item>
    <ion-item
      detail
      *ngFor="let category of documentCategories.data"
      (click)="dismiss({ label: category.name, value: category.id })"
    >
      <ion-label class="font--base">{{ category.name }}</ion-label>
    </ion-item>
  </ion-list>
</fl-select-modal-base>
