import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCurrentTenantApiUrl } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { User } from '@flink-legacy/core/declarations/user.interface';
import { emailConfimation } from '@flink-legacy/core/declarations/email_confimation.interface';
import { IsInvitationValid } from '@flink-legacy/core/declarations/auth_repository.interface';

export interface LoginRequestParams {
  email: string;
  password: string;
}

export interface ApiResponse<T> {
  status?: string;
  success?: boolean;
  statusText?: string;
  data?: T;
  errors?: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  protected BASE_URL: string;
  constructor(private http: HttpClient, tenantStore: Store<TenantState>) {
    tenantStore
      .select(getCurrentTenantApiUrl)
      .pipe()
      .subscribe(url => {
        this.BASE_URL = url;
      });
  }

  signIn(credentials: LoginRequestParams): Observable<{ data: unknown }> {
    return this.http.post<{ data: unknown }>(
      `${this.BASE_URL}/auth/sign_in`,
      credentials
    );
  }

  signOut(): Observable<unknown> {
    return this.http.delete<unknown>(`${this.BASE_URL}/auth/sign_out`);
  }

  resetPassword(email: string, redirect_url: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.BASE_URL}/auth/password`, {
      email,
      redirect_url,
    });
  }

  updatePassword(
    password: string,
    password_confirmation: string,
    options?: unknown
  ): Observable<unknown> {
    return this.http.put<unknown>(
      `${this.BASE_URL}/auth/password`,
      {
        password,
        password_confirmation,
      },
      options
    );
  }

  confirmation(params): Observable<emailConfimation> {
    return this.http.get<emailConfimation>(
      `${this.BASE_URL}/auth/confirmation`,
      {
        params,
      }
    );
  }

  acceptInvitation(
    invitation_token: string,
    password: string
  ): Observable<User> {
    return this.http.put<User>(`${this.BASE_URL}/auth/invitation`, {
      user: {
        invitation_token,
        password,
        password_confirmation: password,
      },
    });
  }

  isInvitationValid(invitation_token: string): Observable<boolean> {
    return this.http
      .get<IsInvitationValid>(
        `${this.BASE_URL}/auth/invitation/validity_check`,
        {
          params: { invitation_token },
        }
      )
      .pipe(map(res => res.valid));
  }
}
