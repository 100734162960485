import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCurrentTenantApiUrl } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';

export interface Version {
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class HealthCheckRepository {
  protected BASE_URL: string;
  constructor(protected http: HttpClient, tenantStore: Store<TenantState>) {
    tenantStore
      .select(getCurrentTenantApiUrl)
      .pipe()
      .subscribe(url => {
        this.BASE_URL = url;
      });
  }

  getVersion(): Observable<Version> {
    return this.http.get<Version>(`${this.BASE_URL}/health_check`);
  }
}
