import { Action } from '@ngrx/store';
import { Tenant } from '../../declarations/tenant.interface';

export const LOAD_CURRENT_TENANT = 'TENANT:LOAD_CURRENT_TENANT';
export const SET_CURRENT_TENANT = 'TENANT:SET_CURRENT_TENANT';

export class LoadCurrentTenantAction implements Action {
  readonly type = LOAD_CURRENT_TENANT;
  constructor() {}
}

export class SetCurrentTenantAction implements Action {
  readonly type = SET_CURRENT_TENANT;
  // tenant ID
  constructor(public payload: Tenant) {}
}
