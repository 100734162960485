import { Component, inject, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CustomModalOptions,
  ModalAbstractComponent,
  ModalService,
} from '@bling-fe/shared/modals/base-modal';
import {
  ConfirmModalComponent,
  ConfirmModalComponentProps,
} from '@flink-legacy/modals/confirm-modal/confirm-modal.component';
import { Optional } from '@flink-legacy/shared/services/util.service';
import { ArticleType } from '@flink-legacy/core/declarations/article.interface';
import { ReportType } from '@flink-legacy/core/declarations/report.interface';
import {
  ConfirmWithReasonModalComponent,
  ConfirmWithReasonModalResult,
} from '@flink-legacy/modals/confirm-with-reason-modal/confirm-with-reason-modal.component';
import { ShareService } from '@flink-legacy/shared/services/share.service';
import { IonicModule } from '@ionic/angular';
import { marker as _ } from '@bling-fe/shared/utils';
import { TranslationModule } from '@flink-legacy/core/translation/translation.module';
import { SvgIconComponent } from '@ngneat/svg-icon';

type DeleteModalProps = Optional<
  ConfirmModalComponentProps,
  'cancelLabel' | 'confirmLabel' | 'confirmColor'
>;

type ReportModalProps = Optional<
  ConfirmModalComponentProps,
  'cancelLabel' | 'confirmLabel' | 'confirmColor' | 'description'
>;
type ActionLabels = Partial<Record<OptionsModalAction, string>>;

export type OptionsModalAction =
  | 'edit'
  | 'share'
  | 'manage'
  | 'delete'
  | 'deleteWithReason'
  | 'flag'
  | 'calendar'
  | 'block'
  | 'duplicate';

export interface OptionsModalProps {
  title?: string;
  deleteModalProps?: DeleteModalProps;
  flagModalProps?: ReportModalProps;
  blockModalProps?: ReportModalProps;
  actionsEnabled?: OptionsModalAction[];
  actionLabels?: ActionLabels;
}

export type OptionsModalDismissType =
  | DismissValue
  | DismissActionWithReason
  | false;

type DismissValue = {
  action: Exclude<OptionsModalAction, 'deleteWithReason' | 'flag' | 'block'>;
};

type DismissActionWithReason = {
  reason_for_action: string;
  action: 'deleteWithReason' | 'flag' | 'block';
};

export type OptionsModalResult = { action: OptionsModalAction } | true;

@NgModule({})
export class SharedModalsUiOptionsModalIconsModule {}

@Component({
  selector: 'fl-shared-modals-ui-options-modal',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslationModule,
    SvgIconComponent,
    SharedModalsUiOptionsModalIconsModule,
  ],
  templateUrl: './shared-modals-ui-options-modal.component.html',
  styleUrls: ['./shared-modals-ui-options-modal.component.scss'],
  providers: [
    {
      provide: ModalAbstractComponent,
      useExisting: SharedModalsUiOptionsModalComponent,
    },
  ],
})
export class SharedModalsUiOptionsModalComponent
  extends ModalAbstractComponent<OptionsModalProps, OptionsModalDismissType>
  implements OnInit
{
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'fl-options auto-height bottom-sheet-modal',
    id: 'OPTIONS',
  };

  hasDivider = false;

  shareEnabled = false;
  private modalService = inject(ModalService);

  public ArticleType = ArticleType;

  constructor() {
    super();
  }

  async ngOnInit() {
    this.props.title ||= _('MODALS.OPTIONS.TITLE');
    this.shareEnabled = await ShareService.enabled;
    this.handleDivider();
  }

  async handleDelete() {
    const shouldDelete = await this.confirmDelete();
    this.dismiss(shouldDelete ? { action: 'delete' } : false, null, 'OPTIONS');
  }

  async handleDeleteWithReason() {
    const shouldDelete = await this.confirmDeleteWithReason();
    this.dismiss(
      shouldDelete?.finishAction
        ? {
            action: 'deleteWithReason',
            reason_for_action: shouldDelete.modalMessage,
          }
        : false,
      null,
      'OPTIONS'
    );
  }

  async handleReportWithReason(action: ReportType) {
    const shouldReport =
      action === 'flag'
        ? await this.confirmReportWithReason()
        : await this.confirmBlockWithReason();
    this.dismiss(
      shouldReport?.finishAction
        ? {
            action: action,
            reason_for_action: shouldReport.modalMessage,
          }
        : false,
      null,
      'OPTIONS'
    );
  }

  public handleAction(action: DismissValue['action']) {
    this.dismiss({ action: action });
  }

  private handleDivider() {
    this.hasDivider =
      ((this.props.actionsEnabled?.includes('flag') ||
        this.props.actionsEnabled?.includes('block')) &&
        (this.props.actionsEnabled?.includes('delete') ||
          this.props.actionsEnabled?.includes('deleteWithReason') ||
          this.props.actionsEnabled?.includes('edit') ||
          this.props.actionsEnabled?.includes('duplicate') ||
          this.props.actionsEnabled?.includes('share') ||
          this.props.actionsEnabled?.includes('calendar'))) ||
      false;
  }

  private async confirmDelete(): Promise<boolean> {
    const confirmed = await this.modalService.presentAndGetResult(
      ConfirmModalComponent,
      {
        cancelLabel:
          this.props.deleteModalProps?.cancelLabel ?? _('COMMON.LABELS.CANCEL'),
        confirmLabel:
          this.props.deleteModalProps?.confirmLabel ??
          _('COMMON.LABELS.DELETE'),
        confirmColor: 'danger',
        title:
          this.props.deleteModalProps?.title ?? _('COMMON.LABELS.DELETE_TITLE'),
        description:
          this.props.deleteModalProps?.description ??
          _('COMMON.LABELS.DELETE_DESCRIPTION'),
      }
    );

    return !!confirmed;
  }

  private async confirmDeleteWithReason(): Promise<ConfirmWithReasonModalResult> {
    return await this.modalService.presentAndGetResult(
      ConfirmWithReasonModalComponent,
      {
        cancelLabel:
          this.props.deleteModalProps?.cancelLabel ?? _('COMMON.LABELS.CANCEL'),
        confirmLabel:
          this.props.deleteModalProps?.confirmLabel ??
          _('COMMON.LABELS.DELETE'),
        confirmColor: 'danger',
        title: this.props.deleteModalProps?.title ?? _('COMMON.LABELS.DELETE'),
        description:
          this.props.deleteModalProps?.description ??
          _('COMMON.LABELS.DELETE_DESCRIPTION'),
      }
    );
  }

  private async confirmReportWithReason(): Promise<ConfirmWithReasonModalResult> {
    return await this.modalService.presentAndGetResult(
      ConfirmWithReasonModalComponent,
      {
        cancelLabel:
          this.props.flagModalProps?.cancelLabel ?? _('COMMON.LABELS.CANCEL'),
        confirmLabel:
          this.props.flagModalProps?.confirmLabel ?? _('COMMON.LABELS.REPORT'),
        confirmColor: this.props.flagModalProps?.confirmColor ?? 'danger',
        title: this.props.flagModalProps?.title ?? _('COMMON.LABELS.REPORT'),
        description:
          this.props.flagModalProps?.description ??
          _('COMMON.LABELS.REPORT_DESCRIPTION'),
      }
    );
  }

  private async confirmBlockWithReason(): Promise<ConfirmWithReasonModalResult> {
    return await this.modalService.presentAndGetResult(
      ConfirmWithReasonModalComponent,
      {
        cancelLabel:
          this.props?.blockModalProps?.cancelLabel ?? _('COMMON.LABELS.CANCEL'),
        confirmLabel:
          this.props?.blockModalProps?.confirmLabel ?? _('COMMON.LABELS.BLOCK'),
        confirmColor: this.props?.blockModalProps?.confirmColor ?? 'danger',
        title:
          this.props?.blockModalProps?.title ?? _('COMMON.LABELS.BLOCK_TITLE'),
        description:
          this.props?.blockModalProps?.description ??
          _('COMMON.LABELS.BLOCK_DESCRIPTION'),
      }
    );
  }
}
