import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HammerModule } from '@angular/platform-browser';

import { environment } from '@bling-fe/shared/env';
import { tenantReducers } from './states/tenant-state/tenant.reducers';
import { TranslationCoreModule } from './translation/translation-core.module';
import { TenantEffects } from './states/tenant-state/tenant.effects';
import { UserEffects } from './states/user-state/user.effects';
import { commentReducers } from './states/comment-state/comment.reducers';
import { newsfeedReducers } from './states/newsfeed-state/newsfeed.reducers';
import { NewsfeedEffects } from './states/newsfeed-state/newsfeed.effects';
import * as userReducers from './states/user-state/user.reducers';
import { GlobalErrorHandler } from './error-handler/global-error-handler';
import { layoutReducer } from './states/layout-state/layout.reducers';
import { LayoutEffects } from './states/layout-state/layout.effects';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { messageNotificationsReducers } from './states/message-notifications-state/message-notifications.reducers';
import { MessageNotificationsEffects } from './states/message-notifications-state/message-notifications.effects';
import { EventsEffects } from './states/events-state/events.effects';
import { eventsFilterReducers } from './states/events-state/events.reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslationCoreModule,
    StoreModule.forRoot({
      userState: userReducers.userReducer,
      usersFilterState: userReducers.userFilterReducer,
      tenantState: tenantReducers,
      commentState: commentReducers,
      newsfeedState: newsfeedReducers,
      eventsFilterState: eventsFilterReducers,
      layoutState: layoutReducer,
      messageNotificationsState: messageNotificationsReducers,
    }),
    EffectsModule.forRoot([
      MessageNotificationsEffects,
      TenantEffects,
      UserEffects,
      NewsfeedEffects,
      EventsEffects,
      LayoutEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    HammerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    provideSvgIconsConfig({
      sizes: {
        xs: '10px',
        sm: '18px',
        md: '16px',
        lg: '20px',
        xl: '24px',
        xxl: '30px',
      },
      defaultSize: 'md',
      icons: [],
    }),
  ],
})
export class CoreModule {}
