import { Component, Input, inject } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';

export type CustomModalOptions = Omit<
  ModalOptions<HTMLIonModalElement>,
  'component' | 'componentProps'
>;

@Component({
  template: '',
})
export abstract class ModalAbstractComponent<PROPS, DISMISS_DATA> {
  // if custom modal options need to be specified
  public static modalOptions: CustomModalOptions = {};

  protected modalController = inject(ModalController);

  @Input() public props!: PROPS;

  async dismiss(data?: DISMISS_DATA, role?: string | null, id?: string) {
    // role with null works just fine => that's why there's the `as`
    await this.modalController.dismiss(data, role as string, id);
  }
}
