<div
  class="inner-content"
  role="main"
>
  <h1 class="text--base text--medium mb-3">
    {{ props.title! | translate }}
  </h1>

  <ion-list class="post-item-popover-menu">
    <!-- only for event -->
    <ion-item
      *ngIf="props.actionsEnabled?.includes('calendar')"
      (click)="handleAction('calendar')"
      button
      lines="none"
      detail="false"
      data-cy="addToCalendar"
    >
      <svg-icon
        slot="start"
        key="calendar-plus"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          props.actionLabels?.calendar ||
            ("COMMON.LABELS.ADD_TO_CALENDAR" | translate)
        }}
      </span>
    </ion-item>

    <ion-item
      *ngIf="shareEnabled && props.actionsEnabled?.includes('share')"
      (click)="handleAction('share')"
      button
      lines="none"
      detail="false"
    >
      <svg-icon
        slot="start"
        key="share"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.share | translate) ||
            ("COMMON.LABELS.SHARE" | translate)
        }}
      </span>
    </ion-item>
    <ion-item
      *ngIf="props.actionsEnabled?.includes('edit')"
      (click)="handleAction('edit')"
      button
      lines="none"
      detail="false"
      data-cy="option-edit"
    >
      <svg-icon
        slot="start"
        key="pencil-line"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.edit | translate) ||
            ("COMMON.LABELS.EDIT" | translate)
        }}
      </span>
    </ion-item>

    <ion-item
      *ngIf="props.actionsEnabled?.includes('duplicate')"
      (click)="handleAction('duplicate')"
      button
      lines="none"
      detail="false"
      data-cy="duplicateEvent"
    >
      <svg-icon
        slot="start"
        key="calendar-blank"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.duplicate | translate) ||
            ("COMMON.LABELS.DUPLICATE" | translate)
        }}
      </span>
    </ion-item>

    <ion-item
      *ngIf="props.actionsEnabled?.includes('manage')"
      (click)="handleAction('manage')"
      button
      lines="none"
      detail="false"
      data-cy="manage-btn"
    >
      <svg-icon
        slot="start"
        key="gear"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.manage | translate) ||
            ("COMMON.LABELS.MANAGE" | translate)
        }}
      </span>
    </ion-item>

    <ion-item
      (click)="handleDelete()"
      *ngIf="props.actionsEnabled?.includes('delete')"
      button
      lines="none"
      detail="false"
      class="delete-item"
      data-cy="option-delete"
    >
      <svg-icon
        slot="start"
        key="trash"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.delete | translate) ||
            ("COMMON.LABELS.DELETE" | translate)
        }}
      </span>
    </ion-item>

    <ion-item
      (click)="handleDeleteWithReason()"
      *ngIf="props.actionsEnabled?.includes('deleteWithReason')"
      button
      lines="none"
      detail="false"
      class="delete-item"
      data-cy="option-delete-with-reason"
    >
      <svg-icon
        slot="start"
        key="trash"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.deleteWithReason | translate) ||
            ("COMMON.LABELS.DELETE" | translate)
        }}
      </span>
    </ion-item>

    <hr
      class="my-2"
      *ngIf="hasDivider"
    />

    <ion-item
      (click)="handleReportWithReason('flag')"
      *ngIf="props.actionsEnabled.includes('flag')"
      button
      lines="none"
      detail="false"
      data-cy="report-btn"
    >
      <svg-icon
        slot="start"
        key="flag"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.flag | translate) ||
            ("COMMON.LABELS.REPORT" | translate)
        }}
      </span>
    </ion-item>

    <ion-item
      (click)="handleReportWithReason('block')"
      *ngIf="props.actionsEnabled?.includes('block')"
      button
      lines="none"
      detail="false"
      data-cy="block-btn"
    >
      <svg-icon
        slot="start"
        key="prohibit"
        class="text--lg mr-4"
      ></svg-icon>
      <span class="text--lg">
        {{
          (props.actionLabels?.block | translate) ||
            ("COMMON.LABELS.BLOCK" | translate)
        }}
      </span>
    </ion-item>
  </ion-list>
</div>
