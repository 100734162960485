<ion-tab-bar
  [style.display]="hide ? 'none' : 'flex'"
  mode="md"
  class="hide-labels-if-display-small"
  [class.hide-labels]="hideLabelsIos$ | async"
>
  <ion-tab-button
    *ngFor="let item of menuItems"
    [routerLink]="[item.url]"
    routerLinkActive="router-link-active"
  >
    <svg-icon
      [key]="item.icon"
      size="xl"
    ></svg-icon>
    <ion-label>{{ item.label | translate }}</ion-label>
    <bl-notifications-badge
      *ngIf="(item.notificationCount$ | async) > 0"
      [count]="item.notificationCount$ | async"
      class="ml-4"
    ></bl-notifications-badge>
  </ion-tab-button>
  <ng-container *ngIf="showMenuToggle">
    <ion-tab-button (click)="toggleMenu()">
      <svg-icon
        key="main-menu-more"
        size="xl"
      ></svg-icon>
    </ion-tab-button>
  </ng-container>
</ion-tab-bar>
