import { NewsfeedFilters } from '@flink-legacy/core/declarations/filter.interface';
import { Action } from '@ngrx/store';

export const LOAD_CURRENT_NEWSFEED_FILTERS =
  'NEWSFEED:LOAD_CURRENT_NEWSFEED_FILTERS';
export const SET_CURRENT_NEWSFEED_FILTERS =
  'NEWSFEED:SET_CURRENT_NEWSFEED_FILTERS';

export class LoadCurrentNewsfeedFiltersAction implements Action {
  readonly type = LOAD_CURRENT_NEWSFEED_FILTERS;
  constructor() {}
}

export class SetCurrentNewsfeedFiltersAction implements Action {
  readonly type = SET_CURRENT_NEWSFEED_FILTERS;
  constructor(public payload: NewsfeedFilters) {}
}
