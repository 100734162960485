import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalAbstractComponent } from './modal-abstract.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalController: ModalController) {}

  public async create<MODAL_PROPS, DISMISS_DATA>(
    modalComponent: typeof ModalAbstractComponent<MODAL_PROPS, DISMISS_DATA>,
    componentProps: MODAL_PROPS
  ): Promise<HTMLIonModalElement> {
    return await this.modalController.create({
      component: modalComponent,
      componentProps: { props: componentProps },
      ...modalComponent.modalOptions,
    });
  }

  public async presentAndGetResult<MODAL_PROPS, DISMISS_DATA>(
    modalComponent: typeof ModalAbstractComponent<MODAL_PROPS, DISMISS_DATA>,
    componentProps: MODAL_PROPS
  ): Promise<DISMISS_DATA | undefined> {
    const modal = await this.create(modalComponent, componentProps);

    const res = await Promise.all([modal.present(), modal.onWillDismiss()]);

    return res[1].data;
  }
}
