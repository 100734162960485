import { Injectable } from '@angular/core';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import {
  PageParams,
  Paginated,
  PaginatedResponse,
} from '@flink-legacy/core/declarations/paginated.interface';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  Identifiable,
  RepositoryAbstract,
  RepositoryParams,
} from '../repositories/repository.abstract';

/**
 * Abstract service for paginated repositories
 *
 * @template T provide *RepositoryParams from corresponding repository
 * @template A do not use
 * @template B do not use
 * @template C do not use
 * @template D do not use
 * @template E do not use
 * @template F do not use
 * @template G do not use
 * @template H do not use
 * @template I do not use
 */
@Injectable({
  providedIn: 'root',
})
export class PaginatedAbstractService<
  T extends RepositoryParams<A, B, C, D, E, F, G, H, I>,
  // A-X generic params are hack to provide something to RepositoryParams.
  // All are derived from T, which is source of truth
  A = T['item'],
  B extends PaginatedResponse<A> = T['all'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C extends Record<string, any> = T['queryParams'],
  D = T['createParams'],
  E extends T['item'] = T['createResponse'],
  F extends Identifiable = T['updateParams'],
  G extends T['item'] = T['updateResponse'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  H extends Record<string, any> = T['deleteParams'],
  I = T['deleteResponse']
> {
  protected data: PaginatedBehaviorSubject<T['item']> =
    new PaginatedBehaviorSubject({
      state: 'loading',
      data: null,
    });

  protected params = new BehaviorSubject<T['queryParams'] & PageParams>({
    page: 1,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

  get data$(): Observable<Fetchable<Paginated<T['item']>>> {
    return this.data.asObservable();
  }

  get params$(): Observable<T['queryParams'] & PageParams> {
    return this.params.asObservable();
  }

  constructor(protected repository: RepositoryAbstract<T>) {}

  private initialState: Fetchable<Paginated<T['item']>> = {
    state: 'loading',
    data: {
      items: [],
      total_pages: -1,
      current_page: 1,
      total_count: 0,
    },
  };

  reset() {
    this.data.next(this.initialState);
  }

  fetch(params?: T['queryParams']) {
    this.data.next(this.initialState);

    this.params.next({ ...params, page: 1 });
    this.repository.findAll({ ...this.params.value }).subscribe({
      next: res => {
        this.data.next({
          state: 'ready',
          data: { ...res, current_page: 1 },
        });
      },
      error: err => this.setErrorState(err),
    });
  }

  refetch(): Promise<void> {
    this.data.next({
      state: 'loading',
    });
    this.params.next({ ...this.params.value, page: 1 });
    return new Promise(resolve => {
      this.repository.findAll({ ...this.params.value }).subscribe({
        next: res => {
          this.data.next({
            state: 'ready',
            data: { ...res, current_page: 1 },
          });
          resolve();
        },
        error: err => {
          this.setErrorState(err);
          resolve();
        },
      });
    });
  }

  fetchNextPage() {
    this.params.next({
      ...this.params.value,
      page: this.params.value.page + 1,
    });
    this.data.next({ ...this.data.value, state: 'loading' });

    this.repository.findAll({ ...this.params.value }).subscribe({
      next: res => {
        this.data.next({
          state: 'ready',
          data: {
            ...this.data.value.data,
            current_page: this.params.value.page,
            items: [...(this.data.value.data?.items ?? []), ...res.items],
          },
        });
      },
      error: err => this.setErrorState(err),
    });
  }

  create(entity: T['createParams']): Observable<T['createResponse']> {
    return this.repository.create(entity).pipe(
      tap(newItem => {
        if (this.data.value.data?.items) {
          this.data.emitUpdateItems(items => [newItem, ...items]);
        }
      })
    );
  }

  update(entity: T['updateParams']): Observable<T['updateResponse']> {
    return this.repository.update(entity).pipe(
      tap(updatedItem => {
        this.data.replaceItem(entity.id, updatedItem);
      })
    );
  }

  delete(
    id: number,
    params?: T['deleteParams']
  ): Observable<T['deleteResponse']> {
    return this.repository.delete(id, params).pipe(
      tap(() => {
        this.data.emitDeleteItem(id);
      })
    );
  }

  private setErrorState(err) {
    // set page back to previos value so we can retry with fetchNextPage
    this.params.next({
      ...this.params.value,
      page: this.params.value.page - 1,
    });
    this.data.next({
      data: this.data.value.data,
      state: 'error',
      error: err,
    });

    throw err;
  }
}

export class PaginatedBehaviorSubject<
  T extends Identifiable
> extends BehaviorSubject<Fetchable<Paginated<T>>> {
  /**
   * Deletes item at given index
   */
  public emitDeleteItem(itemToDelete: number | ((it: T) => boolean)) {
    const selector: (it: T) => boolean =
      typeof itemToDelete === 'number'
        ? it => it.id === itemToDelete
        : itemToDelete;
    const current = this.value.data ? this.value.data.items : [];
    const i = current.findIndex(it => selector(it));
    if (i !== -1) {
      const val = [...current];
      val.splice(i, 1);
      this.next({
        ...this.value,
        data: { ...this.value.data, items: val },
      });
    }
  }

  /**
   * Updates item inside items
   *
   *  we have: items: [{id: 1, val: "a"}, {id: 2, val: "b"}]
   *
   * Usage:
   *
   * 1. replace with object
   * data.replaceItem(2, {id: 2, val: "c"}) ==> items: [{id: 1, val: "a"}, {id: 2, val: "c"}]
   *
   * 2. use existing item value
   * data.replaceItem(2, (item) => {id: 2, val: `${item.val}c`}) ==> items: [{id: 1, val: "a"}, {id: 2, val: "ac"}]
   *
   */
  public replaceItem(id: number, replaceWith: T | ((original: T) => T)) {
    const current = this.value.data ? this.value.data.items : [];
    const i = current.findIndex(it => it.id === id);
    if (i !== -1) {
      const val = [...current];
      val.splice(
        i,
        1,
        typeof replaceWith === 'function'
          ? replaceWith(current[i])
          : replaceWith
      );
      this.next({
        ...this.value,
        data: { ...this.value.data, items: val },
      });
    }
  }

  /**
   * Modify items array easily
   *
   * we have: items: [{id: 1, val: "a"}, {id: 2, val: "b"}]
   *
   * Usage:
   *
   * data.emitUpdateItems((items) => {
   *    // items === [{id: 1, val: "a"}, {id: 2, val: "b"}]
   *    return [{id: 0, val: "xx"}, ...items.map(it => {...it, val: 'x'})]
   * })
   * ==> items: [{id: 0, val: "xx"}, {id: 1, val: "x"}, {id: 2, val: "x"}]
   */

  public emitUpdateItems(updater: (items: T[]) => T[]) {
    const current = [...this.value.data?.items];
    this.next({
      ...this.value,
      data: {
        ...this.value.data,
        items: updater(current),
      },
    });
  }
}
