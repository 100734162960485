import { Injectable, Injector } from '@angular/core';
import {
  DocumentFilterParams,
  FlDocument,
} from '@flink-legacy/core/declarations/document.interface';

import {
  DefineRepositoryParams,
  RepositoryAbstract,
} from '@flink-legacy/shared/repositories/repository.abstract';
import { Observable } from 'rxjs';

export type DocumentRepositoryParams = DefineRepositoryParams<
  FlDocument,
  {
    queryParams: DocumentFilterParams;
  }
>;

@Injectable({
  providedIn: 'root',
})
export class DocumentRepository extends RepositoryAbstract<DocumentRepositoryParams> {
  constructor(injector: Injector) {
    super('documents', injector);
  }

  uploadGroupDocuments(params): Observable<[]> {
    return this.http.post<[]>(`${this.RESOURCE_URL}/create_batch`, params);
  }
}
