import { TenantState } from './../core/states/tenant-state/tenant.state';

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication.service';
import { TokenService } from './services/token.service';
import { Store } from '@ngrx/store';
import { getCurrentTenantApiUrl } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  WHITELIST: string[] = [];

  constructor(
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    tenantStore: Store<TenantState>
  ) {
    tenantStore
      .select(getCurrentTenantApiUrl)
      .pipe()
      .subscribe(url => {
        this.WHITELIST.push(url);
      });
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authenticationService.getAuthData().pipe(
      take(1),
      mergeMap(authData => {
        if (authData && this.WHITELIST.some(url => req.url.match(url))) {
          const headers = {
            'access-token': authData.accessToken,
            client: authData.client,
            expiry: authData.expiry,
            'token-type': authData.tokenType,
            uid: authData.uid,
          };

          req = req.clone({
            setHeaders: headers,
          });
        }

        return next.handle(req).pipe(
          tap(
            (res: HttpEvent<unknown>) => this.handleResponse(res),
            (err: HttpEvent<unknown>) => this.handleErrorResponse(err)
          )
        );
      })
    );
  }

  private handleResponse(
    res: HttpResponse<unknown> | HttpErrorResponse | HttpEvent<unknown>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      this.tokenService.getAuthHeadersFromResponse(res);
    }
  }

  private handleErrorResponse(
    err: HttpEvent<unknown> | HttpErrorResponse
  ): void {
    if (err instanceof HttpErrorResponse) {
      this.tokenService.getAuthHeadersFromResponse(err);
      if (err.status === 401) {
        this.authenticationService.logout({ skipSignOut: true });
      }
    }
  }
}
