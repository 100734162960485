import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Capacitor } from '@capacitor/core';
import { takeUntil, tap } from 'rxjs/operators';

import { User } from '@flink-legacy/core/declarations/user.interface';
import { CommentRepository } from '@flink-legacy/shared/repositories/comment.repository';
import { UserState } from '@flink-legacy/core/states/user-state/user.reducers';
import { CommentState } from '@flink-legacy/core/states/comment-state/comment.state';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { getEditingComment } from '@flink-legacy/core/states/comment-state/comment.selectors';
import { SetEditingComment } from '@flink-legacy/core/states/comment-state/comment.actions';
import { CommentOrigin } from '@flink-legacy/core/declarations/comment.interface';
import { ArticleDetail } from '@flink-legacy/core/declarations/article-detail.interface';
import { PostService } from '@flink-legacy/shared/services/post.service';
import { IonTextarea, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'fl-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TranslateModule,
    NgIf,
    NgFor,
    AsyncPipe,
    FormsModule,
    AvatarComponent,
  ],
})
export class CommentInputComponent implements OnInit {
  @Input() commentOrigin: CommentOrigin;
  @Input() item: ArticleDetail;
  @Output() commentPosted: EventEmitter<void> = new EventEmitter();
  @Output() isEditingComment: EventEmitter<boolean> = new EventEmitter(false);
  @ViewChild(IonTextarea) textarea: IonTextarea;

  comment = '';
  // not null if is editing comment
  editingComment = null;
  isEditing = false;
  // avoid duplicate submit of a comment
  disableSubmitBtn = true;
  disableEditingComment = false;

  ngUnsubscribe = new Subject<void>();

  loggedUser$: Observable<User>;

  constructor(
    private commentRepository: CommentRepository,
    private userStore: Store<UserState>,
    private commentStore: Store<CommentState>,
    private postService: PostService
  ) {}

  ngOnInit() {
    this.loggedUser$ = this.userStore.pipe(select(getCurrentUser));
    this.commentStore
      .pipe(
        select(getEditingComment),
        tap(x => (this.editingComment = x)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        this.comment = res?.body ?? '';
        this.isEditing = res?.body ? true : false;
        this.isEditingComment.emit(this.isEditing);
      });
  }

  checkSubmit() {
    if (Capacitor.getPlatform() === 'web') {
      this.submit();
    }
  }

  cancel() {
    this.commentStore.dispatch(new SetEditingComment(null));
  }

  async submit() {
    if (this.comment) {
      const input = await this.textarea.getInputElement();
      input.blur();
      this.disableSubmitBtn = true;
      this.disableEditingComment = true;
      // edit
      if (this.editingComment) {
        const editingCommentID = this.editingComment.id;
        this.commentRepository
          .updateComment({ ...this.editingComment, body: this.comment })
          .subscribe(res => {
            this.disableEditingComment = false;
            this.commentStore.dispatch(new SetEditingComment(null));
            // Avoid a refresh of the whole page so just replace the comment in existing post
            const idx = this.item.comments.findIndex(
              x => x?.id === editingCommentID
            );
            if (idx !== -1) {
              this.item.comments.splice(idx, 1, res);
            }
          });
      } else {
        // create
        this.commentRepository
          .createFor(this.commentOrigin, this.item.id, { body: this.comment })
          .subscribe({
            next: res => {
              this.comment = '';
              this.disableEditingComment = false;
              // Avoid a refresh of the whole page so just add the new comment to existing post
              this.item.comments.unshift(res);
              this.postService.commentAdded(this.item.id);
              this.commentPosted.emit();
            },
            error: err => {
              // in case of error allow retrial by enabling editing/submit button
              this.disableEditingComment = false;
              this.disableSubmitBtn = false;
              throw err;
            },
          });
      }
    }
  }

  commentChanged(comment) {
    this.disableSubmitBtn = comment.length > 0 ? false : true;
  }
}
