import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

/**
 * LoadingService is responsible for handling app-wide loading spinner,
 * which overlay whole viewport.
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) {}

  async show(options?: Partial<LoadingOptions>) {
    if (!this.loading) {
      await this.create(options);
      await this.loading.present();
    }
  }

  async dismiss() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  async create(options?: Partial<LoadingOptions>) {
    const defaultOptions: LoadingOptions = {
      cssClass: 'fl-loading',
      spinner: 'crescent',
      keyboardClose: true,
      htmlAttributes: {
        'data-cy': 'loading',
      },
    };

    this.loading = await this.loadingCtrl.create({
      ...defaultOptions,
      ...options,
    });
  }
}
