import { createAction, props } from '@ngrx/store';
import { MessageNotificationsWithCount } from '@flink-legacy/core/declarations/message-notifications.interface';

export const SET_MESSAGE_NOTIFICATIONS =
  'MESSAGE_NOTIFICATIONS:SET_MESSAGE_NOTIFICATIONS';

export const SetMessageNotifications = createAction(
  'MESSAGE_NOTIFICATIONS:SET_MESSAGE_NOTIFICATIONS',
  props<{ notifications: MessageNotificationsWithCount }>()
);

export const SubscribeToBENotifications = createAction(
  'MESSAGE_NOTIFICATIONS:SUBSCRIBE_TO_BE_NOTIFICATIONS'
);

export const UnsubscribeFromBENotifications = createAction(
  'MESSAGE_NOTIFICATIONS:UNSUBSCRIBE_FROM_BE_NOTIFICATIONS'
);
