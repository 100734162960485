import { Injectable } from '@angular/core';

import {
  SettlementRepository,
  SettlementRepositoryParams,
} from '../repositories/settlement.repository';
import { PaginatedAbstractService } from './paginated.abstract.service';

@Injectable({
  providedIn: 'root',
})
export class SettlementService extends PaginatedAbstractService<SettlementRepositoryParams> {
  constructor(settlementRepository: SettlementRepository) {
    super(settlementRepository);
  }
}
