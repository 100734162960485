import {
  ChangeDetectorRef,
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LayoutState } from '@flink-legacy/core/states/layout-state/layout.reducers';
import { getScreensize } from '@flink-legacy/core/states/layout-state/layout.selectors';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[flDesktopOnly]',
})
export class DesktopOnlyDirective implements OnDestroy {
  private hasView = false;
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private store: Store<LayoutState>,
    private cdRef: ChangeDetectorRef
  ) {
    this.subscription = this.store
      .pipe(select(getScreensize))
      .subscribe(screen => {
        if (screen === 'desktop' && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
        this.cdRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
