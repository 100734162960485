<ion-content scroll-y="false">
  <div class="d-flex flex-col h-100 ion-padding">
    <div class="flex-1 flex--center flex-col ion-text-center">
      <ion-text
        class="text--normal font--base line-height--xl"
        [innerHTML]="props.message | async"
      ></ion-text>
    </div>
    <div class="flex--center">
      <ion-button
        class="ok-button"
        size="large"
        (click)="close()"
        shape="round"
      >
        {{ "COMMON.LABELS.OK" | translate }}
      </ion-button>
    </div>
  </div>
</ion-content>
