import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, switchMap } from 'rxjs';
import {
  SET_CURRENT_NEWSFEED_FILTERS,
  LOAD_CURRENT_NEWSFEED_FILTERS,
} from './newsfeed.actions';
import { getCurrentNewsfeedFilters } from './newsfeed.selectors';
import { Preferences } from '@capacitor/preferences';
import { INITIAL_NEWSFEED_FILTERS } from '@flink-legacy/core/declarations/filter.interface';

@Injectable()
export class NewsfeedEffects {
  loadCurrentNewsfeedFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CURRENT_NEWSFEED_FILTERS),
      mergeMap(() =>
        from(
          Preferences.get({ key: STORAGE_KEYS.CURRENT_NEWSFEED_FILTERS })
        ).pipe(
          map(v => JSON.parse(v.value)),
          map(t => ({
            type: SET_CURRENT_NEWSFEED_FILTERS,
            payload: t ?? INITIAL_NEWSFEED_FILTERS,
          }))
        )
      )
    )
  );

  saveCurrentNewsfeedFiltersToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_CURRENT_NEWSFEED_FILTERS),
        concatLatestFrom(() => this.store.select(getCurrentNewsfeedFilters)),
        switchMap(([, f]) => {
          if (f !== null) {
            return from(
              Preferences.set({
                key: STORAGE_KEYS.CURRENT_NEWSFEED_FILTERS,
                value: JSON.stringify(f),
              })
            );
          } else {
            return of(f);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store) {}
}
