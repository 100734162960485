<ng-container *ngFor="let item of itemsToRender">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'fl-post-item'">
      <div [class]="type">
        <div
          class="skeleton-card d-flex flex-col ion-justify-content-between p-4"
        >
          <div class="d-flex mt-2">
            <ion-skeleton-text
              class="skeleton-avatar mr-4"
              animated
            ></ion-skeleton-text>
            <div class="d-flex flex-col flex-1">
              <ion-skeleton-text
                class="skeleton-text h-font-base"
                style="width: 70%"
                animated
              ></ion-skeleton-text>
              <ion-skeleton-text
                class="skeleton-text h-font-base mt-2"
                style="width: 100px"
                animated
              ></ion-skeleton-text>
            </div>
          </div>
          <div class="mt-3">
            <ion-skeleton-text
              class="skeleton-text h-font-base"
              style="width: 100%"
              animated
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="skeleton-text h-font-base"
              style="width: 100%"
              animated
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="skeleton-text h-font-base"
              style="width: 80%"
              animated
            ></ion-skeleton-text>
          </div>
          <div class="flex--space-between mt-4 mb-3">
            <ion-skeleton-text
              class="h-font-md"
              style="width: 50px"
              animated
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="h-font-md"
              style="width: 50px"
              animated
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-repair-message'">
      <div [class]="type">
        <div class="skeleton-card p-4">
          <div class="flex--space-between mt-4 mb-3">
            <div class="d-flex flex-1 mr-4 mt-2">
              <ion-skeleton-text
                class="skeleton-avatar mr-4"
                animated
              ></ion-skeleton-text>
              <div class="d-flex flex-col flex-1">
                <ion-skeleton-text
                  class="skeleton-text h-font-base"
                  style="width: 70%"
                  animated
                ></ion-skeleton-text>
                <ion-skeleton-text
                  class="skeleton-text h-font-base mt-2"
                  style="width: 100px"
                  animated
                ></ion-skeleton-text>
                <ion-skeleton-text
                  class="skeleton-text h-font-base mt-2"
                  style="width: 100%"
                  animated
                ></ion-skeleton-text>
                <ion-skeleton-text
                  class="skeleton-text h-font-base"
                  style="width: 100%"
                  animated
                ></ion-skeleton-text>
                <ion-skeleton-text
                  class="skeleton-text h-font-base"
                  style="width: 80%"
                  animated
                ></ion-skeleton-text>
                <ion-skeleton-text
                  class="skeleton-text h-font-base mt-2"
                  style="width: 20%"
                  animated
                ></ion-skeleton-text>
              </div>
            </div>
            <ion-skeleton-text
              class="h-font-md"
              style="width: 20px"
              animated
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-reservable-list-item'">
      <div [class]="type">
        <div class="skeleton-card p-4">
          <div class="d-flex flex-col flex-1">
            <ion-skeleton-text
              class="skeleton-text h-font-base"
              style="width: 70%"
              animated
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="skeleton-text h-font-sm mt-3"
              style="width: 100%"
              animated
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="skeleton-text h-font-base mt-4 mb-1"
              style="width: 100px"
              animated
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-reservation-list-item'">
      <div [class]="type">
        <div class="skeleton-card p-4">
          <div class="d-flex flex-col flex-1">
            <ion-skeleton-text
              class="skeleton-text h-font-base"
              style="width: 30%"
              animated
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="skeleton-text h-font-sm mt-3 mb-1"
              style="width: 70%"
              animated
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-document'">
      <div [class]="type">
        <div class="skeleton-card p-4">
          <div class="d-flex flex-1">
            <ion-skeleton-text
              class="skeleton-svg mr-4"
              animated
            ></ion-skeleton-text>
            <div class="flex--start flex-1">
              <ion-skeleton-text
                class="skeleton-text h-font-sm"
                style="width: 30%"
                animated
              ></ion-skeleton-text>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-event-item'">
      <div [class]="type">
        <div class="skeleton-card p-4">
          <div class="d-flex flex-1">
            <div class="flex-1">
              <ion-skeleton-text
                class="skeleton-text h-font-sm mb-2"
                style="width: 70%"
                animated
              ></ion-skeleton-text>
              <ion-skeleton-text
                class="skeleton-text h-font-sm mb-2"
                style="width: 20%"
                animated
              ></ion-skeleton-text>
              <ion-skeleton-text
                class="skeleton-text h-font-sm mb-2"
                style="width: 20%"
                animated
              ></ion-skeleton-text>
            </div>
            <ion-skeleton-text
              class="skeleton-svg mr-4"
              animated
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-group-item'">
      <div [class]="type">
        <div class="skeleton-card p-4">
          <div class="d-flex flex-1">
            <ion-skeleton-text
              class="skeleton-svg mr-4"
              animated
            ></ion-skeleton-text>
            <div class="flex-1">
              <ion-skeleton-text
                class="skeleton-text h-font-sm mb-2"
                style="width: 20%"
                animated
              ></ion-skeleton-text>
              <ion-skeleton-text
                class="skeleton-text h-font-sm mb-2"
                style="width: 40%"
                animated
              ></ion-skeleton-text>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-user-item'">
      <div [class]="type">
        <div class="skeleton-card flex--start py-3 px-5">
          <ion-skeleton-text
            class="skeleton-avatar skeleton-svg mr-4 flex-shrink-0"
            animated
          ></ion-skeleton-text>
          <ion-skeleton-text
            class="skeleton-text h-font-base pr-5"
            style="width: 50%"
            animated
          ></ion-skeleton-text>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'group-member-item'">
      <div [class]="type">
        <div class="skeleton-card flex--start py-2">
          <ion-skeleton-text
            class="skeleton-avatar skeleton-svg mr-4 flex-shrink-0"
            animated
          ></ion-skeleton-text>
          <ion-skeleton-text
            class="skeleton-text h-font-base"
            style="width: 70%"
            animated
          ></ion-skeleton-text>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'fl-invite-user-item'">
      <div [class]="type + ' mt-2'">
        <div class="skeleton-card flex--start py-2">
          <ion-skeleton-text
            class="skeleton-avatar skeleton-svg mr-4 flex-shrink-0"
            animated
          ></ion-skeleton-text>
          <ion-skeleton-text
            class="skeleton-text h-font-base"
            style="width: 40%"
            animated
          ></ion-skeleton-text>

          <ion-skeleton-text class="skeleton-btn ml-auto"></ion-skeleton-text>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
