export const MAX_TICKET_DESCS_LENGTH = 2000;
export const DEFAULT_TOAST_DURATION = 4000;

export const CREATED_DATE_FORMAT = 'd.M.y HH:mm';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Screensize = 'mobile' | 'desktop';

export const BREAKPOINT = {
  XS: 320,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const DESKTOP_BREAKPOINT = BREAKPOINT.LG;

export const specialHosts: string[] = [
  'wink.abz.ch',
  'app.abl.ch',
  'app.sunnigehof.ch',
];
