<ng-container *ngIf="inputInterface === 'normal'">
  <ion-select
    [formControl]="control"
    [interface]="interface"
    [interfaceOptions]="interfaceOptions"
    [placeholder]="placeholder"
    (click)="onClick($event)"
  >
    <ion-select-option
      *ngIf="!required"
      value=""
    >
      {{ "COMMON.LABELS.NONE" | translate }}
    </ion-select-option>
    <ion-select-option
      *ngFor="let option of options"
      [value]="option.value"
    >
      {{ option.label | translate }}
    </ion-select-option>
  </ion-select>
</ng-container>

<ng-container *ngIf="inputInterface === 'withImage'">
  <div
    (click)="onClick($event)"
    (keyup.space)="onClick($event)"
    tabindex="0"
  >
    <ion-item
      class="with-image-item font--sm text--medium"
      lines="none"
      detail
    >
      <ng-container *ngIf="selectedOption as option; else plholder">
        <ion-thumbnail
          slot="start"
          *ngIf="option.image"
        >
          <ion-img
            [src]="option.image"
            [alt]="option.label"
            aria-hidden="true"
          ></ion-img>
        </ion-thumbnail>
        <span class="ml-2">{{ option.label }}</span>
      </ng-container>
      <ng-template #plholder>
        <span class="color--medium">
          {{ placeholder }}
        </span>
      </ng-template>
    </ion-item>
  </div>
</ng-container>
