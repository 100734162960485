import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '@flink-legacy/core/translation/translation.module';
import { IonicModule } from '@ionic/angular';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { ResponsiveContentComponent } from './responsive-content/responsive-content.component';
import { PortalModule } from '@angular/cdk/portal';

import { TabsNavigationComponent } from './tabs-navigation/tabs-navigation.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { DirectivesModule } from '@flink-legacy/shared/directives/directives.module';
import { PipesModule } from '@flink-legacy/shared/pipes/pipes.module';
import { UiNotificationsBadgeComponent } from '@bling-fe/shared/ui-components/ui-notifications-badge';

@NgModule()
export class LayoutIconsModule {}

@NgModule({
  declarations: [
    BasicLayoutComponent,
    AppLayoutComponent,
    TabsNavigationComponent,
    MainMenuComponent,
    PageHeaderComponent,
    PageLayoutComponent,
    ResponsiveContentComponent,
  ],
  exports: [
    PageHeaderComponent,
    PageLayoutComponent,
    ResponsiveContentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    PortalModule,
    TranslationModule,
    DirectivesModule,
    PipesModule,
    LayoutIconsModule,
    SvgIconComponent,
    UiNotificationsBadgeComponent,
  ],
})
export class LayoutModule {}
