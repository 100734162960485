import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageNotificationsState } from './message-notifications.state';
import {
  MessageNotification,
  RepairMessageNotification,
} from '@flink-legacy/core/declarations/message-notifications.interface';
import { TicketType } from '@flink-legacy/core/declarations/repair-message.interface';

const getFeatureState = createFeatureSelector<MessageNotificationsState>(
  'messageNotificationsState'
);

export const getMessageNotifications = createSelector(
  getFeatureState,
  (state: MessageNotificationsState) => {
    return state.messageNotifications;
  }
);

export const getCommunityNotifications = createSelector(
  getMessageNotifications,
  notifications => notifications.community.notifications
);

export const getCommunityNotificationCount = createSelector(
  getMessageNotifications,
  notifications => notifications.community.count
);

export const getServicesNotifications = createSelector(
  getMessageNotifications,
  notifications => notifications.services.notifications
);

export const getServicesNotificationCount = createSelector(
  getMessageNotifications,
  notifications => notifications.services.count
);

export const getConversationNotificationCount = (conversationId: number) =>
  createSelector(
    getCommunityNotifications,
    (notifications: MessageNotification[]) => {
      const notification = notifications.find(
        notification => notification.notifiable_id === conversationId
      );
      return notification ? notification.notification_count : 0;
    }
  );

export const getRepairMessageNotificationCount = (ticketId: number) =>
  createSelector(
    getServicesNotifications,
    (notifications: MessageNotification[]) => {
      const notification = notifications.find(
        notification => notification.notifiable_id === ticketId
      );
      return notification ? notification.notification_count : 0;
    }
  );

export const getNotificationCountByTicketType = (type: TicketType) =>
  createSelector(
    getServicesNotifications,
    (notifications: RepairMessageNotification[]) => {
      return notifications
        .filter(notification => notification.ticket_type === type)
        .reduce(
          (sum, notification) => sum + notification.notification_count,
          0
        );
    }
  );
