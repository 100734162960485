import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducers';
import { INITIAL_USERS_FILTER_STATE, UserFilterState } from './user.state';

const getFeatureState = createFeatureSelector<UserState>('userState');

export const getCurrentUser = createSelector(
  getFeatureState,
  (state: UserState) => state.currentUser
);

const getUsersFilterFeatureState =
  createFeatureSelector<UserFilterState>('usersFilterState');

export const getUsersFilter = createSelector(
  getUsersFilterFeatureState,
  (state: UserFilterState) => state.usersFilterState
);

export const isUsersFilterActive = createSelector(
  getUsersFilterFeatureState,
  (state: UserFilterState): boolean => {
    const filters = state?.usersFilterState;
    if (!filters) return false;

    return (
      (filters.online_only != undefined &&
        filters.online_only != INITIAL_USERS_FILTER_STATE.online_only) ||
      (filters.new_members != undefined && filters.new_members != false)
    );
  }
);
