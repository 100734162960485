import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LOAD_CURRENT_TENANT, SET_CURRENT_TENANT } from './tenant.actions';
import { Preferences } from '@capacitor/preferences';
import { TenantRepository } from '@flink-legacy/shared/repositories/tenant.repository';
import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCurrentTenant } from './tenant.selectors';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@bling-fe/shared/env';

@Injectable()
export class TenantEffects {
  loadCurrentTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CURRENT_TENANT),
      // load current tenant id from storage
      mergeMap(() =>
        from(Preferences.get({ key: STORAGE_KEYS.CURRENT_TENANT_ID })).pipe(
          map(x => Number(x.value))
        )
      ),
      // fetch all tenants and fill the current tenant to the one selected in storage
      mergeMap(currTenantId =>
        this.tenantRepository.findAll().pipe(
          map(tenants =>
            tenants.find(t => {
              // select the tenant according to environment.tenant
              if (environment.tenant) {
                return t.subdomain.toUpperCase() === environment.tenant;
              }

              // if has tenant defined in url => select it
              // else if has selected tenant in storage => select it
              switch (window.location.hostname) {
                case 'wink.abz.ch':
                  return t.subdomain === 'abz';
                case 'app.abl.ch':
                  return t.subdomain === 'abl';
                case 'app.sunnigehof.ch':
                  return t.subdomain === 'sh';
                default:
                  return (
                    t.subdomain === window.location.hostname.split('.')[0] ||
                    t.id === currTenantId
                  );
              }
            })
          ),
          map(t => ({ type: SET_CURRENT_TENANT, payload: t ?? null }))
        )
      ),
      catchError(() => of({ type: SET_CURRENT_TENANT, payload: null }))
    )
  );

  saveCurrentTenantToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_CURRENT_TENANT),
        concatLatestFrom(() => this.store.select(getCurrentTenant)),
        switchMap(([, t]) => {
          if (t !== 'loading' && t !== null) {
            return from(
              Preferences.set({
                key: STORAGE_KEYS.CURRENT_TENANT_ID,
                value: String(t.id),
              })
            );
          } else {
            return of(t);
          }
        })
      ),
    { dispatch: false }
  );

  setTenantToSentry$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_CURRENT_TENANT),
        concatLatestFrom(() => this.store.select(getCurrentTenant)),
        tap(([, t]) => {
          if (t !== 'loading' && t !== null) {
            Sentry.setTag('tenant', t.name);
          } else {
            Sentry.setTag('tenant', null);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private tenantRepository: TenantRepository,
    private store: Store
  ) {}
}
