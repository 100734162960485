import { HttpErrorResponse } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
// used for touch gestures
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from '@bling-fe/shared/env';
import { RELEASE_VERSION } from './environments/release_version';

Sentry.init({
  dsn: environment.sentry,
  release: RELEASE_VERSION,
  environment: environment.name,
  // uncaught http errors causes the http error to be reported twice
  // https://github.com/getsentry/sentry-javascript/issues/2532#issuecomment-1059201548
  // because we alraedy handle http errors in GlobalErrorHandler
  beforeSend(event, hint) {
    if (hint?.originalException instanceof HttpErrorResponse) {
      return null;
    }
    return event;
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.log(err));
