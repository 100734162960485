<fl-select-modal-base
  [title]="'MODALS.SELECT_POST_SCOPE.TITLE' | translate"
  (closeClick)="dismiss(selectedOption)"
  (search)="searchFilter.next($event)"
  class="ion-page"
>
  <h2 class="color--medium text--sm text--medium pt-5 pb-2">
    {{ "MODALS.SELECT_POST_SCOPE.SUBTITLE" | translate }}
  </h2>

  <ion-list lines="none">
    <ion-item
      detail
      *ngFor="let option of scopeOptions$ | async"
      (click)="dismiss(option)"
    >
      <ion-thumbnail slot="start">
        <img
          [src]="option.image"
          alt=""
          aria-hidden="true"
        />
      </ion-thumbnail>
      <ion-label class="font--base">{{ option.label }}</ion-label>
    </ion-item>
  </ion-list>
</fl-select-modal-base>
