import { Injectable, Injector } from '@angular/core';
import { PaginatedResponse } from '@flink-legacy/core/declarations/paginated.interface';
import {
  Group,
  GroupDetail,
  GroupFilterParams,
  GroupParams,
} from '@flink-legacy/interfaces/group';
import {
  DefineRepositoryParams,
  RepositoryAbstract,
} from '@flink-legacy/shared/repositories/repository.abstract';
import { Observable } from 'rxjs';

export type GroupRepositoryParams = DefineRepositoryParams<
  GroupDetail,
  {
    all: PaginatedResponse<Group>;
    queryParams: GroupFilterParams;
    createParams: GroupParams;
  }
>;

@Injectable({
  providedIn: 'root',
})
export class GroupRepository extends RepositoryAbstract<GroupRepositoryParams> {
  constructor(injector: Injector) {
    super(`/groups`, injector);
  }

  getMyGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.RESOURCE_URL}/my_groups`);
  }

  getRecommendedGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.RESOURCE_URL}/recommended`);
  }
}
