import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponentProps } from '../confirm-modal/confirm-modal.component';
import {
  CustomModalOptions,
  ModalAbstractComponent,
} from '@bling-fe/shared/modals/base-modal';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export type ConfirmWithReasonModalResult =
  | ActionConfirmed
  | ActionAborted
  | undefined;
export interface ActionConfirmed {
  finishAction: true;
  modalMessage: string;
}

export interface ActionAborted {
  finishAction: false;
}

@Component({
  selector: 'fl-confirm-with-reason-modal',
  templateUrl: './confirm-with-reason-modal.component.html',
  styleUrls: ['./confirm-with-reason-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, FormsModule, ReactiveFormsModule],
  providers: [
    // for storybook
    {
      provide: ModalAbstractComponent,
      useExisting: ConfirmWithReasonModalComponent,
    },
  ],
})
export class ConfirmWithReasonModalComponent extends ModalAbstractComponent<
  ConfirmModalComponentProps,
  ConfirmWithReasonModalResult
> {
  confirmReason = new FormControl('');
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'auto-height bottom-sheet-modal',
  };
}
