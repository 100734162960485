import { Component, inject, Input } from '@angular/core';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { UserShort } from '@flink-legacy/core/declarations/user.interface';
import { Store } from '@ngrx/store';
import { getInstantMessagingEnabled } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { UiAvatarComponent } from '@bling-fe/shared/ui-components/ui-avatar';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'fl-avatar',
  standalone: true,
  imports: [AsyncPipe, NgIf, UiAvatarComponent],
  template: `
    <bl-avatar
      *ngIf="user"
      [user]="user"
      [size]="size"
      [clickable]="clickable"
      [showOnlineIndicator]="
        !hideOnlineIndicator && instantMessagingEnabled$ | async
      "
    ></bl-avatar>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class AvatarComponent {
  @Input() user?: UserShort | null;
  @Input() size = 64;
  @Input() clickable = true;
  /**
   * Hide online indicator even if instant messaging is enabled.
   */
  @Input() hideOnlineIndicator = false;

  store = inject(Store<Tenant>);

  instantMessagingEnabled$ = this.store.select(getInstantMessagingEnabled);
}
