import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';

export const types = [
  'fl-post-item',
  'fl-repair-message',
  'fl-reservable-list-item',
  'fl-reservation-list-item',
  'fl-document',
  'fl-event-item',
  'fl-group-item',
  'fl-user-item',
  'group-member-item',
  'fl-invite-user-item',
] as const;

type Types = (typeof types)[number];

@Component({
  selector: 'fl-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, NgSwitch, NgSwitchCase, NgFor],
})
export class SkeletonComponent implements OnInit {
  @Input() type: Types;

  @Input() count = 3;
  itemsToRender = [];

  constructor() {}

  ngOnInit(): void {
    this.itemsToRender = Array(this.count)
      .fill(0)
      .map((_, i) => i + 1);
  }
}
