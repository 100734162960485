import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthData } from '@flink-legacy/core/declarations/auth-data.interface';
import {
  User,
  UserFilterParams,
  UserParams,
} from '@flink-legacy/core/declarations/user.interface';
import { setCurrentUser } from '@flink-legacy/core/states/user-state/user.actions';
import { UserState } from '@flink-legacy/core/states/user-state/user.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  DefineRepositoryParams,
  RepositoryAbstract,
} from './repository.abstract';

export interface PasswordData {
  current_password?: string;
  password: string;
  password_confirmation: string;
}

export type UserRepositoryParams = DefineRepositoryParams<
  User,
  {
    queryParams: UserFilterParams;
    createParams: UserParams;
  }
>;

@Injectable({
  providedIn: 'root',
})
export class UserRepository extends RepositoryAbstract<UserRepositoryParams> {
  constructor(
    injector: Injector,
    private userStore: Store<UserState>,
    private router: Router
  ) {
    super(`users`, injector);
  }

  getCurrentUser(): Observable<User> {
    // different controller, route like /api/v1/current_user
    return this.http.get<User>(`${this.TENANT_API_URL}/current_user`);
  }

  createVotingappLoginToken(): Observable<AuthData> {
    return this.http
      .post<{ auth_data: AuthData }>(
        `${this.TENANT_API_URL}current_user/create_votingapp_login_token/`,
        {}
      )
      .pipe(map(r => r.auth_data));
  }

  updateCurrentUser(user: Partial<UserParams>): Observable<User> {
    return this.http
      .patch<User>(`${this.TENANT_API_URL}/current_user`, user)
      .pipe(
        tap(u => {
          this.userStore.dispatch(setCurrentUser(u));
        })
      );
  }

  acceptTerms(email: string): Observable<User> {
    return this.http
      .put<User>(`${this.TENANT_API_URL}/current_user/accept_terms`, {
        email,
      })
      .pipe(
        tap(u => {
          this.userStore.dispatch(setCurrentUser(u));
          this.router.navigateByUrl('/home');
        })
      );
  }

  updatePassword(passwordData: PasswordData): Observable<User> {
    return this.http.patch<User>(`${this.TENANT_API_URL}/auth`, passwordData);
  }

  register(params): Observable<unknown> {
    return this.http.post(`${this.TENANT_API_URL}/current_user/register`, {
      user: params,
    });
  }

  deleteSelf() {
    return this.http.delete(`${this.TENANT_API_URL}/current_user`);
  }
}
