import {
  TenantRepository,
  TenantRepositoryParams,
} from './../repositories/tenant.repository';
import { Injectable } from '@angular/core';
import { BasicAbstractService } from './basic.abstract.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends BasicAbstractService<TenantRepositoryParams> {
  constructor(tenantRepository: TenantRepository) {
    super(tenantRepository);
  }
}
