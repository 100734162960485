<div class="inner-content ion-text-center">
  <h5 aria-level="1">{{ props.title | translate }}</h5>
  <ion-text class="text--sm">
    {{ props.description | translate }}
  </ion-text>

  <ion-button
    class="mt-6"
    (click)="dismiss(true)"
    expand="block"
    [color]="props.confirmColor || 'primary'"
    data-cy="confirm-btn"
  >
    {{ props.confirmLabel | translate }}
  </ion-button>

  <ion-button
    class="mt-4"
    expand="block"
    color="light"
    (click)="dismiss(false)"
  >
    {{ props.cancelLabel | translate }}
  </ion-button>
</div>
