import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import { Paginated } from '@flink-legacy/core/declarations/paginated.interface';
import { debounce, Observable, Subscription, timer } from 'rxjs';

export interface PaginateDirectiveContext<U, E> {
  $implicit: U[];
  data: Fetchable<Paginated<U>, E>;
  error: E | null;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[paginate]',
  providers: [],
})
export class PaginateDirective<_ extends Fetchable<Paginated<T>, E>, E, T>
  implements OnInit, OnDestroy
{
  private paginateSourceObs!: Observable<Fetchable<Paginated<T>, E>>;
  private paginateSourceObsChanged = false;
  private view: EmbeddedViewRef<PaginateDirectiveContext<T, E>> | undefined;
  private subscription: Subscription = null;

  constructor(
    private templateRef: TemplateRef<PaginateDirectiveContext<T, E>>,
    private viewContainer: ViewContainerRef
  ) {}

  // Types directive context, so when we do *paginate="posts$; let posts" => posts have correct type
  static ngTemplateContextGuard<
    T_S extends Fetchable<Paginated<U_S>, E_S>,
    E_S,
    U_S
  >(
    dir: PaginateDirective<T_S, E_S, U_S>,
    ctx: unknown
  ): ctx is PaginateDirectiveContext<U_S, E_S> {
    return true;
  }

  @Input() public set paginate(obs: Observable<Fetchable<Paginated<T>, E>>) {
    this.paginateSourceObs = obs;
    this.paginateSourceObsChanged = true;
  }

  ngOnInit() {
    if (this.paginateSourceObsChanged) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = this.paginateSourceObs
        .pipe(
          // Emit loading state only if is loading for more than 100ms to avoid skeleton flickering
          debounce(val => (val.state === 'loading' ? timer(100) : timer(0)))
        )
        .subscribe(res => {
          const context: PaginateDirectiveContext<T, E> = {
            $implicit: res.data?.items ?? [],
            error: res.state === 'error' ? res.error : null,
            data: res,
          };

          if (!this.view) {
            this.view = this.viewContainer.createEmbeddedView(
              this.templateRef,
              context
            );
          } else {
            this.view.context = context;
            this.view.markForCheck();
          }
        });
      this.paginateSourceObsChanged = false;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
