export class Forbidden extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'Forbidden';
  }
}

export class InternalServerError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'InternalServerError';
  }
}

export class Unauthenticated extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'Unauthenticated';
  }
}

export class UnprocessableEntity extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'UnprocessableEntity';
  }
}

export class ServiceUnavailable extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'ServiceUnavailable';
  }
}

export class NotFound extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'NotFound';
  }
}

export class NoResponse extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'NoResponse';
  }
}

export class Offline extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'Offline';
  }
}

export class Unknown extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'Unknown';
  }
}

export class BadRequest extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'BadRequest';
  }
}
