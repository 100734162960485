import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

import { CoreModule } from '../../core/core.module';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [],
  exports: [],
  providers: [Calendar],
})
export class ServicesModule {}
