import { UserService } from '../../shared/services/user.service';
import { Component, inject } from '@angular/core';
import { User } from '@flink-legacy/core/declarations/user.interface';
import { Paginated } from '@flink-legacy/core/declarations/paginated.interface';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
} from 'rxjs';
import { Fetchable } from '@flink-legacy/core/declarations/fetchable.interface';
import { ModalAbstractComponent } from '@bling-fe/shared/modals/base-modal';
import { SelectOption } from '@flink-legacy/shared/components/forms/select/select.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SelectModalBaseComponent } from '../select-modal-base/select-modal-base.component';
import { NgFor } from '@angular/common';
import { InfinitePaginationModule } from '@flink-legacy/shared/components/infinite-pagination/infinite-pagination.module';
import { AvatarComponent } from '@flink-legacy/shared/components/avatar/avatar.component';

@Component({
  selector: 'fl-select-user-modal',
  templateUrl: './select-user-modal.component.html',
  styleUrls: ['./select-user-modal.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    IonicModule,
    SelectModalBaseComponent,
    NgFor,
    InfinitePaginationModule,
    AvatarComponent,
  ],
})
export class SelectUserModalComponent extends ModalAbstractComponent<
  { value: User },
  SelectOption<User>
> {
  private userService = inject(UserService);
  users$: Observable<Fetchable<Paginated<User>>> = this.userService.data$;
  public searchFilter = new BehaviorSubject<string>('');

  ionViewWillEnter() {
    this.searchFilter
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(term => {
        this.userService.fetch({
          q: term,
        });
      });
  }

  fetchNextPage() {
    this.userService.fetchNextPage();
  }

  async doRefresh(e) {
    await this.userService.refetch();
    e.complete();
  }
}
