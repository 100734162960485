<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        (click)="dismiss()"
        class="close-btn ion-no-margin"
        [title]="'COMMON.LABELS.CLOSE' | translate"
        [attr.aria-label]="'COMMON.LABELS.CLOSE' | translate"
      >
        <svg-icon
          class="color--dark"
          key="close"
          size="xl"
        ></svg-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      class="text--base text--medium"
      id="select-modal-title"
      role="heading"
      aria-level="1"
    >
      {{ props.title }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button
        [href]="props.pdfUrl"
        [download]="props.title"
        target="_blank"
        class="ion-no-margin"
        [title]="'COMMON.LABELS.DOWNLOAD' | translate"
        [attr.aria-label]="'COMMON.LABELS.DOWNLOAD' | translate"
      >
        <svg-icon
          class="color--dark"
          key="share"
          size="xl"
        ></svg-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<pdf-viewer
  [src]="props.pdfUrl"
  [original-size]="false"
  [autoresize]="true"
></pdf-viewer>
