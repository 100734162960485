/**
 * A utility function to mark translatable strings or arrays of strings for extraction.
 * This function is a no-op (does nothing) at runtime but serves as a marker for translation tooling.
 *
 * @param key - A string or an array of strings to be marked for translation.
 * @returns The same input key, unmodified.
 */
export function marker<T extends string | string[]>(key: T): T {
  return key;
}
