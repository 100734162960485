import { ArticleEventFilter } from '@flink-legacy/core/declarations/article.interface';
import { Action } from '@ngrx/store';

export const LOAD_CURRENT_EVENTS_FILTERS = 'EVENTS:LOAD_CURRENT_EVENTS_FILTERS';
export const SET_CURRENT_EVENTS_FILTERS = 'EVENTS:SET_CURRENT_EVENTS_FILTERS';

export class LoadCurrentEventsFiltersAction implements Action {
  readonly type = LOAD_CURRENT_EVENTS_FILTERS;
  constructor() {}
}

export class SetCurrentEventsFiltersAction implements Action {
  readonly type = SET_CURRENT_EVENTS_FILTERS;
  constructor(public payload: ArticleEventFilter) {}
}
