import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  CustomModalOptions,
  ModalAbstractComponent,
} from '@bling-fe/shared/modals/base-modal';

export interface ConfirmModalComponentProps {
  title: string;
  description: string;
  confirmLabel: string;
  confirmColor?: 'primary' | 'danger';
  cancelLabel: string;
}

@Component({
  selector: 'fl-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule, IonicModule],
  providers: [
    // for storybook
    { provide: ModalAbstractComponent, useExisting: ConfirmModalComponent },
  ],
})
export class ConfirmModalComponent extends ModalAbstractComponent<
  ConfirmModalComponentProps,
  boolean
> {
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'auto-height bottom-sheet-modal',
  };
}
