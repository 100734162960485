import {
  Breakpoint,
  Screensize,
} from '@flink-legacy/core/declarations/constants';
import { createAction, props } from '@ngrx/store';

export const handleResize = createAction(
  'LAYOUT:HANDLE_RESIZE',
  props<{ width: number }>()
);

export const setBreakpoint = createAction(
  'LAYOUT:SET_BREAKPOINT',
  props<{ breakpoint: Breakpoint }>()
);

export const setScreensize = createAction(
  'LAYOUT:SET_SCREENSIZE',
  props<{ screensize: Screensize }>()
);

export const setPageTransitionAnimated = createAction(
  'LAYOUT:SET_PAGE_TRANSITION_ANIMATED',
  props<{ animated: boolean }>()
);
