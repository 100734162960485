import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import * as Sentry from '@sentry/angular-ivy';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  handleError(error) {
    // capture only errors with Sentry

    if (error instanceof Error) {
      Sentry.captureException(error);
    }

    if (error instanceof HttpErrorResponse) {
      this.errorHandlerService.handleHttpError(error);
    } else {
      const rejection = error?.rejection;
      if (rejection instanceof HttpErrorResponse) {
        this.errorHandlerService.handleHttpError(rejection);
      } else {
        this.errorHandlerService.handleError(error);
      }
    }
  }
}
