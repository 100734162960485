import {
  Breakpoint,
  Screensize,
} from '@flink-legacy/core/declarations/constants';
import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from './layout.actions';

export interface LayoutState {
  screensize: Screensize;
  breakpoint: Breakpoint;
  pageTransitionAnimated: boolean;
}

export const initialState: LayoutState = {
  screensize: null,
  breakpoint: null,
  pageTransitionAnimated: true,
};

export const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.setBreakpoint, (state, { breakpoint }) => ({
    ...state,
    breakpoint,
  })),
  on(LayoutActions.setScreensize, (state, { screensize }) => ({
    ...state,
    screensize,
  })),
  on(LayoutActions.setPageTransitionAnimated, (state, { animated }) => ({
    ...state,
    pageTransitionAnimated: animated,
  }))
);
