<ion-card>
  <ion-card-content class="d-flex">
    <svg-icon
      slot="start"
      class="color--primary font--xl"
      key="warning"
      aria-hidden="true"
    ></svg-icon>
    <div class="ml-3">
      <h6 class="text--bold text--base color--dark m-0 mb-1">
        {{ title }}
      </h6>
      <p class="m-0 text--xs">
        {{ content }}
        <ng-content></ng-content>
      </p>
    </div>
  </ion-card-content>
</ion-card>
