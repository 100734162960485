<div
  (click)="openUpload()"
  tabindex="0"
  (keyup.space)="openUpload()"
>
  <ng-content></ng-content>
</div>
<input
  type="file"
  name="file"
  (change)="fileChanged($event)"
  id="fileInput"
  multiple="true"
  class="d-none"
  [accept]="validFileTypes"
  #fileInput
/>
