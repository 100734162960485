// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Tenant } from '../declarations/tenant.interface';
import { getCurrentTenant } from '../states/tenant-state/tenant.selectors';
import { filter, first, map, switchMap } from 'rxjs/operators';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';
// we merge translations we have in /assets/ and override it with translations
// that are defined in tenant settings

// this loader is invoked on first app load + all language changes

export const createTranslateLoader = (http: HttpClient, store: Store) =>
  new TranslateTenantAvareHttpLoader(http, store);

export class TranslateTenantAvareHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, private store: Store) {}

  public getTranslation(lang: string): Observable<JSON> {
    // translations for current tenant, if has no, return just {}
    const tenantSpecificTranslations$ = this.store
      .select(getCurrentTenant)
      .pipe(
        filter(x => x !== 'loading'),
        map(t => t as Tenant),
        first(),
        switchMap(t =>
          t?.tenant_setting?.translations_de
            ? this.http.get(t.tenant_setting.translations_de)
            : of({})
        )
      );

    const headers = new HttpHeaders({
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });
    const staticTranslations$ = this.http
      .get(`/assets/i18n/${lang}.json`, { headers })
      .pipe();

    return forkJoin({
      tenantTranslations: tenantSpecificTranslations$,
      staticTranslations: staticTranslations$,
    }).pipe(
      map(({ tenantTranslations, staticTranslations }) =>
        merge(staticTranslations, this.flatternKeys(tenantTranslations))
      )
    );
  }
  // https://github.com/lodash/lodash/issues/2240#issuecomment-418820848
  private flatternKeys(obj, path = []) {
    return !isObject(obj)
      ? { [path.join('.')]: obj }
      : reduce(
          obj,
          (cum, next, key) =>
            merge(cum, this.flatternKeys(next, [...path, key])),
          {}
        );
  }
}
