import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
  DefineRepositoryParams,
  RepositoryAbstract,
} from '@flink-legacy/shared/repositories/repository.abstract';
import {
  CommentDeleteParams,
  CommentOrigin,
  CommentParams,
  Comment,
} from '@flink-legacy/core/declarations/comment.interface';
import { HttpParams } from '@angular/common/http';

export interface CommentsFindAllResponse {
  comments: Comment[];
  total_pages: number;
}

type CommentRepositoryParams = DefineRepositoryParams<
  Comment,
  {
    all: CommentsFindAllResponse;
  }
>;

@Injectable({
  providedIn: 'root',
})
export class CommentRepository extends RepositoryAbstract<CommentRepositoryParams> {
  constructor(injector: Injector) {
    super(`/comments`, injector);
  }

  deleteComment(
    comment: Comment,
    params?: CommentDeleteParams
  ): Observable<unknown> {
    return this.http.delete(
      `${this.TENANT_API_URL}/articles/${comment.article_id}/comments/${comment.id}`,
      { params: params as unknown as HttpParams }
    );
  }

  updateComment(comment: Comment): Observable<Comment> {
    const { body } = comment;
    return this.http.patch<Comment>(
      `${this.TENANT_API_URL}/articles/${comment.article_id}/comments/${comment.id}`,
      { body }
    );
  }

  createFor(
    commentOrigin: CommentOrigin,
    originId: number,
    params: CommentParams
  ) {
    return this.http.post<Comment>(
      `${this.TENANT_API_URL}/${commentOrigin}/${originId}/comments`,
      params
    );
  }
}
