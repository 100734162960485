import { WS_Conversation } from './conversation.interface';
import { MessageNotifications } from './message-notifications.interface';
import { WS_RepairMessage } from './repair-message.interface';

export enum ActionCableChannel {
  RepairMessageChannel = 'RepairMessageChannel',
  ConversationChannel = 'ConversationChannel',
  NotificationsChannel = 'NotificationsChannel',
}

// type of websocket data based on ActionCableChannel
export type ActionCableChannelType<T> =
  T extends ActionCableChannel.RepairMessageChannel
    ? WS_RepairMessage
    : T extends ActionCableChannel.ConversationChannel
    ? WS_Conversation
    : T extends ActionCableChannel.NotificationsChannel
    ? MessageNotifications
    : never;
