import { SetCurrentTenantAction, SET_CURRENT_TENANT } from './tenant.actions';
import { initialState, TenantState } from './tenant.state';

export const tenantReducers = (
  state: TenantState = initialState,
  action: Actions
): TenantState => {
  let newState: TenantState;
  switch (action.type) {
    case SET_CURRENT_TENANT:
      newState = {
        ...state,
        currentTenant: action.payload,
      };
      break;
    default:
      newState = state;
  }

  return newState;
};

export type Actions = SetCurrentTenantAction;
