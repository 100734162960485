<ng-container *ngIf="data">
  <ion-refresher
    *flMobileOnly
    slot="fixed"
    (ionRefresh)="doRefresh($event)"
  >
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <!-- spinner on top, when we already have some items rendered & is loading -->
  <ng-container
    *ngIf="data.state === 'loading' && data.data?.items?.length > 0"
  >
    <div class="ion-text-center">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
  </ng-container>

  <!-- render items when some -->
  <ng-container *ngIf="data.data?.items?.length > 0">
    <ng-content></ng-content>
  </ng-container>

  <!-- empty -->
  <ng-container
    *ngIf="data.state === 'ready' && data.data?.items?.length === 0"
  >
    <ng-content select="[ifEmpty]"></ng-content>
  </ng-container>

  <!-- loading -->
  <ng-container *ngIf="data.state === 'loading'">
    <ng-content select="[ifLoading]"></ng-content>
  </ng-container>

  <!-- loaded all -->
  <ng-container
    *ngIf="
      data.state === 'ready' &&
      data.data?.items?.length > 0 &&
      data.data?.current_page >= data.data?.total_pages
    "
  >
    <ng-content select="[ifLoadedAll]"></ng-content>
  </ng-container>

  <!-- error -->
  <ng-container *ngIf="data.state === 'error'">
    <ng-content select="[ifError]"></ng-content>
  </ng-container>

  <ion-infinite-scroll
    #postsInfiniteScroll
    (ionInfinite)="handleInfiniteLoad()"
  >
    <ion-infinite-scroll-content
      loadingSpinner="crescent"
    ></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ng-container>
