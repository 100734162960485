import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TenantSetting } from '@flink-legacy/core/declarations/tenant-setting.interface';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { ScriptService } from 'ngx-script-loader';
import { filter } from 'rxjs/operators';
import tinycolor from 'tinycolor2';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  favIcon: HTMLLinkElement = this.document?.querySelector('#flinkIcon');
  favPng32: HTMLLinkElement = this.document?.querySelector('#flinkPng32');
  favPng16: HTMLLinkElement = this.document?.querySelector('#flinkPng16');
  favTouchIcon: HTMLLinkElement =
    this.document?.querySelector('#flinkTouchIcon');
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
    private title: Title,
    private scriptService: ScriptService
  ) {}

  public init() {
    this.store
      .select(getCurrentTenant)
      .pipe(filter(x => x !== 'loading' && x !== null))
      .subscribe((t: Tenant) => {
        this.setMetaNameAndFavicon(t.subdomain, t.tenant_setting.app_name);
        this.setAppTheme(t.tenant_setting);
        this.setNativeAppTheme();
        if (t.tenant_setting.app_friendly_analytics_site_id) {
          this.initAnalytics(t.tenant_setting.app_friendly_analytics_site_id);
        }
      });
  }

  public setAppTheme(settings: TenantSetting) {
    Object.entries(settings.colors).forEach(([colorName, colorValue]) => {
      this.setColor(
        colorValue,
        colorName.replace('color_', '').replace('_', '-')
      );
    });
  }

  public setNativeAppTheme() {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }

  setColor(color: string, lable: string) {
    const currentColor = color;
    const { r, g, b } = tinycolor(currentColor).toRgb();

    const variablesToSet = [
      [`--ion-color-${lable}`, currentColor],
      [`--ion-color-${lable}-rgb`, `${r}, ${g}, ${b}`],
      [
        `--ion-color-${lable}-shade`,
        `#${tinycolor.mix(currentColor, '#000', 12).toHex()}`,
      ],
      [
        `--ion-color-${lable}-tint`,
        `#${tinycolor.mix(currentColor, '#fff', 10).toHex()}`,
      ],
    ];

    variablesToSet.forEach(([vr, val]) => {
      this.document.documentElement.style.setProperty(vr, val);
    });
  }

  setMetaNameAndFavicon(tenantSubdomain: string, appName: string) {
    this.title?.setTitle(appName);
    const separatedTenant = ['ABZ', 'ABL', 'BGF', 'SH'];
    if (this.favIcon && this.favPng32 && this.favPng16 && this.favTouchIcon) {
      if (_.includes(separatedTenant, tenantSubdomain.toUpperCase())) {
        this.favIcon.href = `assets/icon/${tenantSubdomain.toLowerCase()}/favicon.svg`;
        this.favPng32.href = `assets/icon/${tenantSubdomain.toLowerCase()}/favicon-32.png`;
        this.favPng16.href = `assets/icon/${tenantSubdomain.toLowerCase()}/favicon-16.png`;
        this.favTouchIcon.href = `assets/icon/${tenantSubdomain.toLowerCase()}/apple-touch-icon.png`;
      } else {
        this.favIcon.href = 'assets/icon/favicon.svg';
        this.favPng32.href = 'assets/icon/favicon-32.png';
        this.favPng16.href = 'assets/icon/favicon-16.png';
        this.favTouchIcon.href = 'assets/icon/apple-touch-icon.png';
      }
    }
  }

  initAnalytics(id: string) {
    return this.scriptService
      .loadScript('../../../assets/js/friendly-analytics.js', {
        siteId: id,
      })
      .subscribe();
  }
}
