import { User } from '@flink-legacy/core/declarations/user.interface';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReservationsEnabledGuard implements CanActivateChild {
  constructor(private userStore: Store<User>) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userStore.select(getCurrentUser).pipe(
      filter(x => x !== null),
      map(user => user as User),
      map(user => !user.friend),
      first()
    );
  }
}
