import { Injectable, Injector } from '@angular/core';
import { Report } from '@flink-legacy/core/declarations/report.interface';
import {
  DefineRepositoryParams,
  RepositoryAbstract,
} from '@flink-legacy/shared/repositories/repository.abstract';

type ReportRepositoryParams = DefineRepositoryParams<Report>;

@Injectable({
  providedIn: 'root',
})
export class ReportRepository extends RepositoryAbstract<ReportRepositoryParams> {
  constructor(injector: Injector) {
    super('reports', injector);
  }
}
