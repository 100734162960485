import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'fl-empty-box',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <section>
      <h1 class="text--md mb-2">
        {{ title ?? ('COMMON.EMPTY_BOX.TITLE' | translate) }}
      </h1>
      <div class="base-paragraph">
        {{ content ?? ('COMMON.EMPTY_BOX.CONTENT' | translate) }}
      </div>
    </section>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      section {
        border-radius: var(--ion-border-radius);
        background-color: var(--ion-color-light);
        padding: 1.25rem 1rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyBoxComponent {
  @Input() title?: string | null;
  @Input() content?: string | null;
}
