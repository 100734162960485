import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@bling-fe/shared/utils';
import { DEFAULT_TOAST_DURATION } from '@flink-legacy/core/declarations/constants';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private router: Router,
    private toastController: ToastController,
    private translate: TranslateService
  ) {}

  async init() {
    // End early if we are not running on native platform
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const { receive } = await this.requestPermissions();

    if (receive === 'granted') {
      this.addListeners();

      await this.register();
    }
  }

  getNotificationToken(): Promise<{ value: string }> {
    return Preferences.get({ key: STORAGE_KEYS.PUSH_NOTIFICATION });
  }

  public async handleOnTap(data: { [key: string]: unknown }) {
    if (data?.['article_id']) {
      this.router.navigate(['post', data?.['article_id']]);
      return;
    }

    if (data?.['group_id']) {
      this.router.navigate(['groups', data?.['group_id']]);
      return;
    }

    if (data?.['daily_reservation']) {
      this.router.navigate(['daily-reservation', data?.['daily_reservation']]);
      return;
    }

    if (data?.['hourly_reservation']) {
      this.router.navigate([
        'hourly-reservation',
        data?.['entity_type'],
        data?.['hourly_reservation'],
      ]);
      return;
    }

    if (data?.['repair_message_id']) {
      this.router.navigate(['ticket', data?.['repair_message_id']]);
      return;
    }

    if (data?.['parking_reservation']) {
      // TODO: parking reservation, does not exist
    }

    if (data?.['conversation_id']) {
      this.router.navigate(['instant-messaging', data['conversation_id']]);
      return;
    }

    this.router.navigate(['home']);
  }

  /**
   * While iOS will show notification in foreground, Android or desktop will not so we show toast instead
   */
  public handleNotificationInForeground(notification: PushNotificationSchema) {
    if (
      Capacitor.getPlatform() === 'android' ||
      !Capacitor.isNativePlatform()
    ) {
      this.toastController
        .create({
          header: notification.title,
          message: notification.body,
          color: 'dark',
          position: 'top',
          duration: DEFAULT_TOAST_DURATION,
          buttons: [
            {
              side: 'end',
              text: this.translate.instant(_('COMMON.PUSH_NOTIFICATION.SHOW')),
              handler: () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { data }: { data: any } = notification;
                this.handleOnTap(data);
              },
            },
          ],
        })
        .then(toast => toast.present());
    }
  }

  /**
   * Register phone to receive push via APNS/FCM (Apple/Google)
   */
  private register(): Promise<void> {
    return PushNotifications.register();
  }

  /**
   * Ask for permission to allow notifications.
   *
   * iOS - prompt user to allow it
   * Android - no such support, automatically granted
   */
  private requestPermissions(): Promise<PermissionStatus> {
    return PushNotifications.requestPermissions();
  }

  /**
   * Add listeners that reacts on defined events from PushNotifications plugin
   */
  private addListeners() {
    // Registration event
    this.onRegistrationEvent();

    // RegistrationError event
    this.onRegistrationErrorEvent();

    // PushNotificationReceived event
    this.onPushNotificationReceivedEvent();

    // PushNotificationActionPerformed event
    this.onPushNotificationActionPerformedEvent();
  }

  private setNotificationToken(token: string) {
    Preferences.set({ key: STORAGE_KEYS.PUSH_NOTIFICATION, value: token });
  }

  private onRegistrationEvent() {
    PushNotifications.addListener('registration', (token: Token) => {
      this.setNotificationToken(token.value);
    });
  }

  private onRegistrationErrorEvent() {
    PushNotifications.addListener('registrationError', (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error('Error: ' + JSON.stringify(error));
    });
  }

  /**
   * Event fired when app received notification.
   * While app is in background, this will not trigger -> https://github.com/ionic-team/capacitor-plugins/issues/200
   */
  private onPushNotificationReceivedEvent() {
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.handleNotificationInForeground(notification);
      }
    );
  }

  /**
   * When user taps on notification in device's notification center
   */
  private onPushNotificationActionPerformedEvent() {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (action: ActionPerformed) => {
        // Handle tap on notification in notification area
        if (action.actionId === 'tap') {
          await this.handleOnTap(action?.notification?.data);
        }
      }
    );
  }
}
