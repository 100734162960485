import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  CustomModalOptions,
  ModalAbstractComponent,
} from '@bling-fe/shared/modals/base-modal';

@Component({
  selector: 'fl-update-info-modal',
  templateUrl: './update-info-modal.component.html',
  styleUrls: ['./update-info-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, AsyncPipe, NgIf],
  providers: [
    {
      provide: ModalAbstractComponent,
      useExisting: UpdateInfoModalComponent,
    },
  ],
})
export class UpdateInfoModalComponent extends ModalAbstractComponent<
  {
    message: Observable<string>;
  },
  void
> {
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'fl-modal',
  };

  close(): void {
    this.dismiss();
  }
}
